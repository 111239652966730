<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>管理首页</el-breadcrumb-item>
            <el-breadcrumb-item>首页</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <div class="block" style="margin-top: 0;">
        <div class="block-header">
            <div class="block-title">
                常用操作
            </div>
        </div>
        <div class="block-body">
            <div class="offen-menus">
                <el-tag v-for="tag in tags" :key="tag.name" closable @click="nav(tag)" @close="tagClose(tag)">
                    {{tag.name}}
                </el-tag>
            </div>
        </div>
    </div>
    <div class="block-nobg">
        <div class="wx-row data-nums" v-if="sys">
            <div class="data-num">
                <div class="label">月新增设备（台）</div>
                <div class="num">{{sys.month_device}}</div>
            </div>
            <div class="data-num">
                <div class="label">已绑定设备（台）</div>
                <div class="num">{{sys.bind_device}}</div>
            </div>
            <div class="data-num">
                <div class="label">月新增用户（人）</div>
                <div class="num">{{sys.month_member}}</div>
            </div>
            <div class="data-num">
                <div class="label">总用户数（人）</div>
                <div class="num">{{sys.total_member}}</div>
            </div>
        </div>
    </div>
    <div class="block">
        <div id="myChart" :style="{width: '100%', height: '600px'}"></div>
    </div>
    <div class="block" v-if="sys">
        <div class="block-header">
            <div class="block-title">系统信息</div>
        </div>
        <div class="block-body wx-row wx-row-wrap">
            <div class="sys-item wx-row">
                <span class="label">系统版本</span>
                <span class="value">1.0.2</span>
            </div>
            <div class="sys-item wx-row">
                <span class="label">数据库版本</span>
                <span class="value">Mysql {{sys.mysql}}</span>
            </div>
            <div class="sys-item wx-row">
                <span class="label">Linux</span>
                <span class="value">CentOS {{sys.os}}</span>
            </div>
            <div class="sys-item wx-row">
                <span class="label">PHP版本</span>
                <span class="value">{{sys.php}}</span>
            </div>
            <div class="sys-item wx-row">
                <span class="label">CPU</span>
                <span class="value">{{sys.cpu}}核</span>
            </div>
            <div class="sys-item wx-row">
                <span class="label">内存</span>
                <span class="value">{{sys.memory}}</span>
            </div>
            <div class="sys-item wx-row">
                <span class="label">存储空间</span>
                <span class="value">{{sys.total}}</span>
            </div>
            <div class="sys-item wx-row">
                <span class="label">可用空</span>
                <span class="value">{{sys.free}}</span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            tags: [
                {
                    url: '/devices',
                    name: '设备管理'
                },
                {
                    url: '/customers',
                    name: '客户'
                },
                {
                    url: '/datum_logs',
                    name: '设备数据'
                },
                {
                    url: '/members',
                    name: '用户管理'
                },
            ],
            sys: null,
            charts: []
        }
    },
    mounted() {
        this.getIndex();
    },
    methods: {
        getIndex() {
            this.$util.get('index').then(res => {
                this.sys = res.sys
                this.charts = res.charts
                this.drawLine();
            })
        },
        nav(tag) {
            this.$router.push(tag.url)
        },
        tagClose(tag) {
            this.tags.splice(this.tags.indexOf(tag), 1);
        },
        drawLine(){
            // 基于准备好的dom，初始化echarts实例
            let myChart = this.$echarts.init(document.getElementById('myChart'))
            let that = this
            // 绘制图表
            myChart.setOption({
                title: {
                    text: '本周数据概览',
                    left: '50px',
                    top: '50px',
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['客户数', '用户数', '设备数'],
                    top: '50px'
                },
                grid: {
                    left: '50px',
                    right: '50px',
                    bottom: '50px',
                    top: '150px',
                    // containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                        name: '客户数',
                        type: 'line',
                        smooth: true,
                        data: that.charts[0]
                    },
                    {
                        name: '用户数',
                        type: 'line',
                        smooth: true,
                        data: that.charts[1]
                    },
                    {
                        name: '设备数',
                        type: 'line',
                        smooth: true,
                        data: that.charts[3]
                    },
                ]
            });
        }
    }
}
</script>

<style scoped>
.data-num{
    text-align: center;
    background-color: #fff;
    padding: 20px 0;
    border-radius: 10px;
    width: calc(20% - 15px);
}
.data-num .label{
    font-size: 14px;
    margin-bottom: 10px;
}
.data-num .num{
    font-size: 24px;
}
.sys-item{
    width: calc(50% - 20px);
    padding: 0 0 20px;
}
</style>