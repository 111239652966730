<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>系统</el-breadcrumb-item>
            <el-breadcrumb-item>管理员</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索名称" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
            <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="realname" label="姓名"></el-table-column>
            <el-table-column prop="mobile" label="手机号"></el-table-column>
            <el-table-column prop="email" label="邮箱"></el-table-column>
            <el-table-column prop="username" label="登录账号"></el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <el-popconfirm v-if="scope.row.username != 'admin'" placement="top" confirm-button-text='确定' cancel-button-text='取消' icon-color="red" title="确定修改状态吗？" @cancel="scope.row.status = (scope.row.status == 1) ? 0 : 1" @confirm="changeStatus(scope.row)" class="btn-pop">
                        <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" slot="reference"></el-switch>
                    </el-popconfirm>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" label="添加日期">
                <template slot-scope="scope">
                    {{scope.row.created_at.substring(0, 10)}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm placement="top" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row)" class="btn-pop" v-if="scope.$index != 0">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" :rules="rules" label-width="120px" :inline="false" size="small">
            <el-form-item label="姓名" prop="realname">
                <el-input v-model="info.realname"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="mobile">
                <el-input v-model="info.mobile"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input v-model="info.email"></el-input>
            </el-form-item>
            <el-form-item label="登录账号" prop="username">
                <el-input v-model="info.username" :disabled="info.id?true:false"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="password">
                <el-input v-model="info.password"></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="device_type_id">
                <el-select v-model="info.tempRoles" placeholder="选择角色" multiple clearable filterable>
                    <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        var checkPassword = (rule, value, callback) => {
            console.log(this.info)
            if(this.info.id > 0) {
                if(value) {
                    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g
                    if(reg.test(value)) {
                        callback()
                    } else {
                        callback(new Error('至少8个字符，包含大写字母、小写字母和数字'))
                    }
                } else {
                    callback()
                }
            } else {
                if (!value) {
                    callback(new Error('密码不能为空'));
                } else {
                    const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g
                    if(reg.test(value)) {
                        callback()
                    } else {
                        callback(new Error('至少8个字符，包含大写字母、小写字母和数字'))
                    }
                }
            }
        }
        return {
            filter: {
                keyword: ''
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saveType: 1, // 1新增 2编辑
            info: {
                realname: '',
                mobile: '',
                email: '',
                username: '',
                password: '',
                status: 1,
                tempRoles: []
            },
            rules: {
                realname: [{
                    required: true,
                    message: '请填写真实姓名',
                    trigger: 'blur'
                }],
                username: [{
                    required: true,
                    message: '请填写登录账号',
                    trigger: 'blur'
                }],
                email: [{
                    required: true,
                    message: '请填写邮件地址',
                    trigger: 'blur'
                }],
                password: [{
                    validator: checkPassword, 
                    trigger: 'blur'
                }],
            },
            roles: []
        }
    },
    mounted() {
        this.getTableData()
        this.getRoles()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('adminers', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
            })
        },
        getRoles() {
            this.$util.get('ajax/roles').then(res=>{
                this.roles = res
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
        },
        onEdit(row) {
            this.saveType = 2
            this.info = {
                id: row.id,
                realname: row.realname,
                mobile: row.mobile,
                email: row.email,
                username: row.username,
                password: '',
                status: row.status,
                tempRoles: []
            }
            row.roles.forEach(role => {
                this.info.tempRoles.push(role.id)
            });
            this.infoShow = true
        },
        onDel(row) {
            this.$util.del('adminers/' + row.id).then(() => {
                this.getTableData()
            })
        },
        onQuick(id, field, val) {
            this.$util.put('adminers/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    if (this.saveType == 1) {
                        this.$util.post('adminers', this.info).then(res => {
                            if(res.code > 0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.infoShow = false
                        })
                    } else {
                        this.$util.put('adminers/' + this.info.id, this.info).then(res => {
                            if(res.code > 0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.getTableData()
                            this.infoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        resetInfo() {
            this.info = {
                realname: '',
                mobile: '',
                email: '',
                username: '',
                password: '',
                status: 1,
                tempRoles: []
            }
        },
        changeStatus(row) {
            this.$util.put('adminers/status', {
                id: row.id
            }).then(() => {
                this.getTableData()
            })
        },
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
</style>
