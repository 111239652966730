<!--
 * @Descripttion: 
 * @version: 
 * @Author: watson
 * @Email: wxlanling@qq.com
 * @Date: 2021-09-13 07:18:49
 * @LastEditors: watson
 * @LastEditTime: 2021-09-14 08:37:23
-->
<template>
<el-container class="mainer">
    <el-aside width="240px">
        <div class="brand wx-row wx-row-center">
            <el-image :src="require('@/assets/mark.png')" fit="fill" :lazy="true" class="mark"></el-image>
            <span>INLEMS 管理平台</span>
        </div>
        <el-menu router :default-active="activeIndex" background-color="#2D4D98" text-color="#fff" active-text-color="#D4ECAA">
            <div v-for="item in menus" :key="item.id" class="menu">
                <el-submenu v-if="item.children" :index="item.url">
                    <template slot="title"><i :class="item.icon"></i> {{item.name}}</template>
                    <el-menu-item-group>
                        <div v-for="subItem in item.children" :key="subItem.id">
                            <el-submenu v-if="subItem.children" :index="subItem.url">
                                <template slot="title"><i :class="subItem.icon"></i> {{subItem.name}}</template>
                                <el-menu-item-group>
                                    <div v-for="thrItem in subItem.children" :key="thrItem.id">
                                        <el-submenu v-if="thrItem.children" :index="thrItem.url">
                                            <template slot="title"><i :class="thrItem.icon"></i> {{thrItem.name}}</template>
                                            <el-menu-item-group>
                                                <el-menu-item v-for="fthItem in thrItem.children" :key="fthItem.id" :index="fthItem.url"><i :class="fthItem.icon"></i> {{fthItem.title}}</el-menu-item>
                                            </el-menu-item-group>
                                        </el-submenu>
                                        <el-menu-item else :index="thrItem.url"><i :class="thrItem.icon"></i> {{thrItem.name}}</el-menu-item>
                                    </div>
                                </el-menu-item-group>
                            </el-submenu>
                            <el-menu-item :index="subItem.url" v-else><i :class="subItem.icon"></i> {{subItem.name}}</el-menu-item>
                        </div>
                    </el-menu-item-group>
                </el-submenu>
                <el-menu-item v-else :index="item.url"><i :class="item.icon"></i> {{item.name}}</el-menu-item>
            </div>
        </el-menu>
    </el-aside>
    <el-container>
        <el-header height="120">
            <el-row type="flex" class="row-bg" justify="space-between">
                <el-col :span="18">
                    
                </el-col>
                <el-col :span="6">
                    <el-menu background-color="#281C72" text-color="#fff" active-text-color="#fff" class="el-menu-notify" mode="horizontal">
                        <el-menu-item index="1" v-if="adminer">{{adminer.username}}</el-menu-item>
                        <el-menu-item index="2" @click="logout"><i class="el-icon-switch-button"></i></el-menu-item>
                    </el-menu>
                </el-col>
            </el-row>
            <div class="page-tabs">
                <el-tabs v-model="activeIndex" type="card" closable @tab-click="tabClick" v-if="pageTabs.length" @tab-remove="tabRemove">
                    <el-tab-pane :key="item.route" v-for="item in pageTabs" :label="item.meta.title" :name="item.route">

                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-header>
        <el-main>
            <transition name="fade" mode="out-in">
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive">
                        <!-- 这里是会被缓存的视图组件 -->
                    </router-view>
                </keep-alive>
            </transition>
            <transition name="fade" mode="out-in">
                <router-view v-if="!$route.meta.keepAlive">
                    <!-- 这里是不被缓存的视图组件 -->
                </router-view>
            </transition>
        </el-main>
        <el-footer>
            <el-row type="flex" class="footer-bg" justify="space-between">
                <el-col :span="24">
                    <div class="copy-content">Copyright &copy; 力盟科技</div>
                </el-col>
            </el-row>
        </el-footer>
    </el-container>
</el-container>
</template>

<script>
export default {
    data() {
        return {
            adminer: null,
        }
    },
    mounted() {
        this.adminer = JSON.parse(localStorage.getItem('adminer'))
        this.$util.get('ajax/menus').then(res => {
            this.$store.commit('set_menus', res)
        })
        if (this.$route.path != '/dashboard' && this.$route.path != '/') {
            const tabNow = {
                route: this.$route.path,
                name: this.$route.title,
                meta: this.$route.meta,
                query: this.$route.query
            }
            let flag = false
            this.$store.state.pageTabs.forEach(tab => {
                if (tab.route == tabNow.route) {
                    flag = true
                }
            });
            if (!flag) {
                this.$store.commit('set_active_index', this.$route.path);
                this.$store.commit('add_tabs', tabNow);
            }
        }
    },
    methods: {
        // tab切换时，动态的切换路由
        tabClick() {
            let path = this.activeIndex;
            if (this.$route.path != path) {
                this.$router.replace({
                    path: path
                });
            }
        },
        tabRemove(targetName) {
            // 首页不可删除
            if (targetName == '/dashboard') {
                return;
            }
            this.$store.commit('delete_tabs', targetName);
            if (this.activeIndex === targetName) {
                // 设置当前激活的路由
                if (this.pageTabs && this.pageTabs.length >= 1) {
                    this.$store.commit('set_active_index', this.pageTabs[this.pageTabs.length - 1].route);
                    this.$router.replace({
                        path: this.activeIndex
                    });
                } else {
                    this.$router.replace({
                        path: '/dashboard'
                    });
                }
            }
        },
        logout() {
            this.$confirm('确定退出登录吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(()=>{
                localStorage.removeItem('token')
                localStorage.removeItem('roles')
                localStorage.removeItem('adminer')
                this.$router.push('/login')
            })
        },
    },
    computed: {
        pageTabs() {
            return this.$store.state.pageTabs;
        },
        activeIndex: {
            get() {
                return this.$store.state.activeIndex;
            },
            set(val) {
                this.$store.commit('set_active_index', val);
            }
        },
        permissions() {
            return this.$store.state.permissions
        },
        menus() {
            return this.$store.state.menus
        },
    },
    watch: {
        '$route'(to) {
            console.log(to)
            if(this.$moment().diff(localStorage.getItem('loginTime'), 'minutes', true) >= 30) {
                localStorage.removeItem('token')
                localStorage.removeItem('loginTime')
                localStorage.removeItem('roles')
                localStorage.removeItem('adminer')
                this.$router.push('/login')
            }
            let flag = false;
            for (let option of this.pageTabs) {
                if (option.route === to.path) {
                    flag = true;
                    this.$store.commit('set_active_index', to.path);
                    break
                }
            }
            if (!flag) {
                this.$store.commit('add_tabs', {
                    route: to.path,
                    name: to.title,
                    meta: to.meta,
                    query: to.query
                });
                this.$store.commit('set_active_index', to.path);
            }
        }
    }
}
</script>

<style scoped>
.mainer {
    background-color: #F1F3F8;
    height: 100%;
}

.el-header {
    padding: 0;
}

.el-header{
    background-color: #281C72;
}
.el-aside {
    background-color: #2D4D98;
}

.page-tabs {
    background-color: #fff;
    padding: 20px 20px 0;
}

.page-tabs /deep/ .el-tabs__header {
    margin-bottom: 0;
}

.brand {
    background-color: #281C72;
    height: 60px;
    text-align: center;
    color: #fff;
}

.page-tabs /deep/ .el-tabs__content {
    padding: 0;
}

.el-menu {
    border-right: none;
}
.el-menu.el-menu--horizontal{
    border-bottom: none;
}
.el-menu-item.is-active{
    background-color: #281C72;
}

.el-footer {
    background-color: #f6f6f6;
}

.copy-content {
    font-size: 12px;
    line-height: 60px;
    color: #888;
    text-align: center;
}

.el-image {
    vertical-align: middle;
}
.mark{
    width: 40px;
    margin-right: 10px;
}
</style>