<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>设备</el-breadcrumb-item>
            <el-breadcrumb-item>所有设备</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-select v-model="filter.customer" placeholder="客户名称搜索" size="mini" clearable filterable>
                        <el-option v-for="customer in customers" :key="customer.id" :label="customer.name" :value="customer.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="filter.type" placeholder="设备类型" size="mini" clearable filterable>
                        <el-option v-for="deviceType in device_types" :key="deviceType.id" :label="deviceType.name" :value="deviceType.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索名称 / MAC地址" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
            <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table border :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="code" label="设备编码"></el-table-column>
            <el-table-column prop="customer" label="绑定客户">
                <template slot-scope="scope">
                    <div v-if="scope.row.customer">{{scope.row.customer.name}}</div>
                    <div>
                        <el-button type="danger" size="mini" v-if="scope.row.customer" @click="unbind(scope.row.id)">解绑客户</el-button>
                        <el-button type="primary" size="mini" v-else @click="onEdit(scope.row)">绑定客户</el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="device_type.name" label="设备类型" align="center"></el-table-column>
            <el-table-column prop="sensors" label="传感器">
                <template slot-scope="scope">
                    <div class="sensor-box" v-for="sensor in scope.row.sensors" :key="sensor.id">
                        <el-tag closable @click="onEditsensor(sensor)" @close="onShowDelsensor(sensor)">{{sensor.name}}</el-tag>
                    </div>
                    <el-button type="default" class="button-new-tag" size="small" @click="onAddsensor(scope.row.id)">+ 新传感器</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <el-popconfirm placement="top" confirm-button-text='确定' cancel-button-text='取消' icon-color="red" title="确定修改状态吗？" @cancel="scope.row.status = (scope.row.status == 1) ? 0 : 1" @confirm="changeStatus(scope.row)" class="btn-pop">
                        <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" slot="reference"></el-switch>
                    </el-popconfirm>
                </template>
            </el-table-column>
            <el-table-column label="备份" width="240" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.backup" style="font-size: 10px">
                        [ <a :href="scope.row.backup.filepath" target="_blank">下载备份</a> ]<br>
                        备份时间：{{scope.row.backup.backup_at}}
                    </div>
                    <el-popconfirm placement="top" confirm-button-text='备份' cancel-button-text='取消' icon-color="red" title="备份将在后台执行，确定备份数据吗？" @confirm="onBackup(scope.row)" class="btn-pop">
                        <el-input v-model="adminPassword" placeholder="请输入超管密码"></el-input>
                        <el-button size="mini" type="info" slot="reference" style="margin-top: 10px;">备份设备数据</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="400">
                <template slot-scope="scope">
                    <el-button size="mini" type="success" @click="onView(scope.row)">详情</el-button>
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm placement="top" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row)" class="btn-pop">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                    <el-popconfirm placement="top" confirm-button-text='清空' cancel-button-text='取消' icon-color="red" title="确定清空数据吗？" @confirm="onClearConfirm(scope.row)" class="btn-pop">
                        <el-input v-model="adminPassword" placeholder="请输入超管密码"></el-input>
                        <el-button size="mini" type="danger" slot="reference" style="margin-top: 10px;">清空设备数据</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" :rules="rules" label-width="120px" :inline="false" size="small">
            <el-form-item label="设备类型" prop="type">
                <el-select v-model="info.type" placeholder="选择设备类型" clearable filterable>
                    <el-option v-for="item in device_types" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属客户" prop="customer_id">
                <el-select v-model="info.customer_id" placeholder="选择服务器" clearable filterable>
                    <el-option v-for="item in customers" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input v-model="info.name"></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="en_name">
                <el-input v-model="info.en_name" placeholder="请保持中文数字编号"></el-input>
            </el-form-item>
            <el-form-item label="编号" prop="code">
                <el-input v-model="info.code"></el-input>
            </el-form-item>
            <el-form-item label="MAC地址" prop="mac">
                <el-input v-model="info.mac"></el-input>
            </el-form-item>
            <el-form-item label="固件版本" prop="version">
                <el-input v-model="info.version" disabled></el-input>
            </el-form-item>
            <el-form-item label="允许放置地区" prop="area">
                <el-cascader v-model="info.allow_place" clearable filterable :options="regions" :props="{ expandTrigger: 'hover' }"></el-cascader>
            </el-form-item>
            <el-form-item label="使用时间段" prop="use_range">
                <el-date-picker v-model="device_use_range" value-format="yyyy-MM-dd" type="daterange" size="normal" range-separator="-" start-placeholder="请选择开始日期" end-placeholder="请选择截至日期" @change="changeUsed">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="传感器" v-for="(sensor, si) in info.sensors" :key="sensor.id" :prop="'sensors.' + si + '.id'">
                <el-select v-model="sensor.type" placeholder="选择传感器类型" clearable filterable>
                    <el-option v-for="item in sensor_types" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <el-input class="sensor-name" v-model="sensor.name" placeholder="使用场景" clearable></el-input>
                <el-button @click.prevent="delsensor(sensor)">删除</el-button>
            </el-form-item>
            <el-form-item label="添加传感器">
                <el-button type="primary" size="small" @click="addsensor">添加传感器</el-button>
            </el-form-item> -->
            <el-form-item label="状态" prop="status" v-if="info.status !== undefined">
                <el-switch v-model="info.status" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>

    <el-dialog title="设备详情" :visible.sync="showDetailStatus" width="80%" @close="clearDetail" v-if="detail" :close-on-click-modal="false">
        <div class="device-header">
            <div class="device-img">
                <el-image :src="detail.device_type.thumb" fit="fill" :lazy="true"></el-image>
            </div>
            <div class="device-info">
                <div class="device-name">{{detail.name}}</div>
                <div class="device-desc">{{detail.device_type.description}}</div>
            </div>
        </div>
        <div class="filter">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="传感器">
                    <el-checkbox-group v-model="currentsensorIds">
                        <el-checkbox :label="sensor.id" v-for="sensor in detail.sensors" :key="sensor.id">{{sensor.name}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="数据类型">
                    <el-radio-group v-model="currentDatumType" @change="changeDatumType">
                        <el-radio :label="datumType.name" v-for="datumType in datumTypes" :key="datumType.id" :disabled="datumType.type == 2">{{datumType.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-date-picker v-model="chartRange" type="datetimerange" start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期" size="mini" clearable value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="drawLine" size="mini">查询</el-button>
                    <el-button type="success" @click="onChartExport" size="mini">导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="sensor-tabs">
            <div class="sensor-tab">

            </div>
        </div>
        <div class="chart" ref="chart">
        </div>
        <span slot="footer">
            <el-button @click="showDetailStatus = false">关闭</el-button>
        </span>
    </el-dialog>

    <el-dialog title="编辑传感器" :visible.sync="sensorInfoShow" width="50%" @close="resetsensor" :close-on-click-modal="false">
        <el-form :model="sensorInfo" ref="sensorInfo" :rules="sensorRules" label-width="120px" :inline="false" size="small">
            <el-form-item label="传感器类型" prop="type">
                <el-select v-model="sensorInfo.type" placeholder="选择传感器类型" clearable filterable @change="setsensorData">
                    <el-option v-for="item in sensor_types" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input v-model="sensorInfo.name"></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="en_name">
                <el-input v-model="sensorInfo.en_name" placeholder="请保持中文数字编号"></el-input>
            </el-form-item>
            <el-form-item label="编号" prop="code">
                <el-input v-model="sensorInfo.code" disabled></el-input>
            </el-form-item>
            <el-form-item label="MAC地址" prop="mac">
                <el-input v-model="sensorInfo.mac"></el-input>
            </el-form-item>
            <el-form-item label="线条颜色" prop="color">
                <el-color-picker v-model="sensorInfo.color"></el-color-picker>
            </el-form-item>
            <el-form-item label="固件版本" prop="version">
                <el-input v-model="sensorInfo.version" disabled></el-input>
            </el-form-item>
            <el-form-item label="使用时间段" prop="use_range
            ">
                <el-date-picker v-model="sensorInfo.use_range" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="请选择开始日期" end-placeholder="请选择截至日期"></el-date-picker>
            </el-form-item>
            <el-divider content-position="left">传感器参数配置 <strong style="color: red;">(*注：以下均为必填项)</strong></el-divider>
            <div>
                <el-form-item :label="datumType.name" v-for="(datumType, di) in this.sensorInfo.tempDatumTypes" :key="datumType.id" :prop="'tempDatumTypes.' + di + '.id'">
                    <el-input class="data-value" v-model="datumType.min" placeholder="最小值" clearable>
                        <template slot="prepend">最小值</template>
                    </el-input>
                    <el-input class="data-value" v-model="datumType.max" placeholder="最大值" clearable>
                        <template slot="prepend">最大值</template>
                    </el-input>
                    <el-input class="data-value" v-model="datumType.adjust" placeholder="校准值" clearable>
                        <template slot="prepend">校准值</template>
                    </el-input>
                    <el-input class="data-value" v-model="datumType.decimals" placeholder="小数位" clearable>
                        <template slot="prepend">小数位</template>
                    </el-input>
                </el-form-item>
            </div>
            <el-divider content-position="left">耗材参数配置</el-divider>
            <el-form-item :label="consumableType.name" v-for="(consumableType, ci) in this.sensorInfo.tempConsumableTypes" :key="consumableType.id" :prop="'tempConsumableTypes.' + ci + '.id'">
                <el-date-picker v-model="consumableType.use_range" value-format="yyyy-MM-dd" type="daterange" size="normal" range-separator="至" start-placeholder="请选择开始日期" end-placeholder="请选择截至日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="状态" prop="status" v-if="sensorInfo.status !== undefined">
                <el-switch v-model="sensorInfo.status" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="sensorInfoShow = false">取消</el-button>
            <el-button type="primary" @click="subsensor">提交</el-button>
        </span>
    </el-dialog>

    <!-- 确认删除传感器 -->
    <el-dialog title="删除传感器" :visible.sync="dialogDelsensor" width="30%">
        <span>确认删除传感器吗？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogDelsensor = false">取 消</el-button>
            <el-button type="primary" @click="onDelsensor">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 确认清除数据 -->
    <el-dialog title="清除设备数据" :visible.sync="dialogClear" width="30%">
        <div style="margin-bottom: 32px;">确定清除 <strong style="color: #f90000;">“{{currentDevice ? currentDevice.name : ''}}”</strong> 设备数据吗？</div>
        <el-form :model="sensorInfo" ref="sensorInfo" :rules="sensorRules" label-width="120px" :inline="false" size="small">
            <el-form-item label="日期节点" prop="type">
                <el-date-picker v-model="clearInfo.datetime" type="datetime" placeholder="该日期节点之前的数据将被清空" size="mini" clearable value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="管理员密码" prop="password">
                <el-input v-model="clearInfo.password"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogClear = false">取 消</el-button>
            <el-button type="primary" @click="onClear">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            filter: {
                keyword: ''
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saveType: 1, // 1新增 2编辑
            info: {
                name: '',
                en_name: '',
                code: '',
                mac: '',
                type: '',
                customer_id: '',
                allow_place: [],
                use_range: [],
                sensors: [],
                version: ''
            },
            rules: {
                name: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'blur'
                }],
                en_name: [{
                    required: true,
                    message: '请填写英文名称',
                    trigger: 'blur'
                }],
                mac: [{
                    required: true,
                    message: '请填写MAC地址',
                    trigger: 'blur'
                }],
            },
            customers: [],
            servers: [],
            device_types: [],
            sensor_types: [],
            regions: [],
            showDetailStatus: false,
            detail: null,
            showsensorStatus: false,
            sensorInfo: {
                name: '',
                en_name: '',
                code: '',
                mac: '',
                color: '',
                version: '',
                type: '',
                device_id: '',
                use_range: [],
                datum_types: [],
                consumable_types: [],
                tempDatumTypes: [],
                tempConsumableTypes: [],
                status: 1
            },
            datumTypes: [],
            consumableTypes: [],
            sensorInfoShow: false,
            sensorRules: {
                name: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'blur'
                }],
                en_name: [{
                    required: true,
                    message: '请填写英文名称',
                    trigger: 'blur'
                }],
                color: [{
                    required: true,
                    message: '请设置传感器颜色',
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: '请填写编码',
                    trigger: 'blur'
                }],
            },
            ws: '',
            chartOption: {},
            chart: '',
            chartRange: [],
            currentsensorIds: [],
            currentDatumType: '',
            currentDatumUnit: '',
            dialogDelsensor: false,
            currentDelsensor: null,
            currentDevice: null,
            dialogClear: false,
            adminPassword: '',
            device_use_range: '',
            clearInfo: {
                datetime: this.$moment().subtract('1', 'years').format('yyyy-MM-DD 00:00:00'),
                password: ''
            }
        }
    },
    mounted() {
        this.getTableData()
        this.getRegions()
        this.getCustomers()
        this.getDeviceTypes()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('devices', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
                if (this.showDetailStatus) {
                    this.tableData.forEach(device => {
                        if (device.id == this.detail.id) {
                            console.log(this.detail.id)
                            this.onView(device)
                        }
                    })
                }
            })
        },
        getCustomers() {
            this.$util.get('ajax/customers').then(res => {
                this.customers = res
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
            this.device_use_range = [this.$moment().format('yyyy-MM-DD'), this.$moment().add(1, 'years').format('yyyy-MM-DD')]
            this.getDeviceTypes()
        },
        changeUsed(val) {
            console.log(val)
            console.log(this.info)
        },
        onEdit(row) {
            this.saveType = 2
            this.info = {
                id: row.id,
                name: row.name,
                en_name: row.en_name,
                code: row.code,
                mac: row.mac,
                type: row.type,
                customer_id: row.customer_id || '',
                allow_place: row.allow_place,
                sensors: row.sensors,
                version: row.version
            }
            this.device_use_range = [row.used_at, row.expired_at]
            this.infoShow = true

            this.getDeviceTypes()
        },
        onDel(row) {
            this.$confirm('此操作将永久删除该设备并删除该设备下的传感器数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'danger'
            }).then(() => {
                this.$util.del('devices/' + row.id).then(res => {
                    if(res.code > 0) {
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                        return false
                    }
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getTableData()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        onQuick(id, field, val) {
            this.$util.put('devices/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        onView(row) {
            this.detail = row
            // this.$util.get('devices/'+row.id).then()
            let datumTypes = [];
            if (row.sensors.length > 0) {
                row.sensors[0].datum_types.forEach(datumType => {
                    datumTypes.push({
                        name: datumType.name,
                        id: datumType.id,
                        unit: datumType.unit
                    })
                });
                if(datumTypes.length > 0) {
                    this.currentDatumType = datumTypes[0].name
                    this.currentDatumUnit = datumTypes[0].unit
                }
            }
            this.datumTypes = datumTypes
            console.log(datumTypes)
            let sensorIds = []
            row.sensors.forEach(sensor => {
                sensorIds.push(sensor.id)
            })
            this.currentsensorIds = sensorIds
            this.showDetailStatus = true
            this.chartRange = [this.$moment().format('yyyy-MM-DD 00:00:00'), this.$moment().format('yyyy-MM-DD HH:mm:ss')]
            if (row.sensors.length > 0) {
                this.drawLine()
            }
            // this.ws = new WebSocket("/wss");
            // this.ws.onopen = function (evt) {
            //     console.log(evt)
            //     console.log("Connection open ...");
            //     this.ws.send("Hello WebSockets!");
            // };

        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    if(this.device_use_range[0] == null || this.device_use_range[1] == null) {
                        this.$message.error('请选择使用时间段')
                        return
                    }
                    this.info.use_range = this.device_use_range
                    if (this.saveType == 1) {
                        this.$util.post('devices', this.info).then(res => {
                            if (res.code > 0) {
                                this.$message.error(res.msg)
                                return
                            }
                            this.getTableData()
                            this.infoShow = false
                        })
                    } else {
                        this.$util.put('devices/' + this.info.id, this.info).then(res => {
                            if (res.code > 0) {
                                this.$message.error(res.msg)
                                return
                            }
                            this.getTableData()
                            this.infoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        resetInfo() {
            this.info = {
                name: '',
                en_name: '',
                code: '',
                mac: '',
                type: '',
                customer_id: '',
                allow_place: [],
                use_range: [],
                sensors: [],
                version: ''
            }
        },
        onClearConfirm(row) {
            this.currentDevice = row
            this.dialogClear = true
        },
        onClear() {
            let row = this.currentDevice
            console.log(this.clearInfo)
            this.$util.post('devices/clear/' + row.id, this.clearInfo).then(res => {
                if(res.code > 0) {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                    return false
                }
                
                this.dialogClear = false
                this.$message({
                    type: 'success',
                    message: '清除完成!'
                });
                this.getTableData()
            })
            // this.$prompt('请输入管理员密码', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'danger'
            // }).then(res => {
            //     console.log(this.clearInfo)
            //     this.$util.post('devices/clear/' + row.id, {
            //         password: res.value
            //     }).then(res => {
            //         if(res.code > 0) {
            //             this.$message({
            //                 type: 'error',
            //                 message: res.msg
            //             });
            //             return false
            //         }
            //         this.$message({
            //             type: 'success',
            //             message: '清除完成!'
            //         });
            //         this.getTableData()
            //     })
            // }).catch(() => {
            //     this.$message({
            //         type: 'info',
            //         message: '已取消清除'
            //     });
            // });
        },
        onBackup(row) {
            this.$prompt('请输入管理员密码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'danger'
            }).then(res => {
                this.$util.post('devices/backup/' + row.id, {
                    password: res.value
                }).then(res => {
                    if(res.code > 200) {
                        this.$message.error(res.msg);
                        return false
                    }
                    this.$message({
                        type: 'success',
                        message: res.msg
                    });
                    this.getTableData()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消备份'
                });
            });
        },
        changeDatumType(val) {
            this.datumTypes.forEach(datumType => {
                if (datumType.name == val) {
                    this.currentDatumUnit = datumType.unit
                }
            })
        },
        changeStatus(row) {
            this.$util.put('devices/status', {
                id: row.id
            }).then(() => {
                this.getTableData()
            })
        },
        getRegions() {
            this.$util.get('ajax/areas').then(res => {
                this.regions = res
            })
        },
        getDeviceTypes() {
            this.$util.get('ajax/device_types').then(res => {
                this.device_types = res
            })
        },
        getsensors() {
            this.$util.get('ajax/sensor_types').then(res => {
                this.sensor_types = res
            })
        },
        beforeUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error('上传图片只能是 JPG,PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
        uploadLogoSuccess(res) {
            this.info.logo = res.data;
        },
        addsensor() {
            this.info.sensors.push({
                type: '',
                name: ''
            })
        },
        delsensor(item) {
            var index = this.info.sensors.indexOf(item)
            if (index !== -1) {
                this.info.sensors.splice(index, 1)
            }
        },
        unbind(id) {
            this.$confirm('此操作将解除该设备绑定的客户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$util.put('devices/' + id + '/customer', {
                    customer_id: 0
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '解绑成功!'
                    });
                    this.getTableData()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消解绑'
                });
            });
        },
        clearDetail() {
            this.chart.dispose()
        },
        onAddsensor(deviceId) {
            this.getsensors()
            this.sensorInfo.device_id = deviceId
            this.sensorInfo.use_range = [this.$moment().format('yyyy-MM-DD'), this.$moment().add(1, 'years').format('yyyy-MM-DD')]
            this.sensorInfoShow = true
        },
        onEditsensor(row) {
            let info = {
                id: row.id,
                name: row.name,
                en_name: row.en_name,
                code: row.code,
                mac: row.mac,
                color: row.color,
                version: row.version,
                type: row.type,
                device_id: row.device_id,
                datum_types: row.datum_types,
                consumable_types: row.consumable_types,
                tempDatumTypes: [],
                tempConsumableTypes: [],
                status: row.status
            }
            row.sensor_type.datum_types.forEach(sdatumType=>{
                row.datum_types.forEach(datumType=>{
                    if(sdatumType.id == datumType.id) {
                        info.tempDatumTypes.push({
                            id: datumType.id,
                            name: datumType.name,
                            min: datumType.pivot.min,
                            max: datumType.pivot.max,
                            adjust: datumType.pivot.adjust,
                            decimals: datumType.pivot.decimals
                        })
                    }
                })
            })
            row.consumable_types.forEach(consumableType=>{
                info.tempConsumableTypes.push({
                    id: consumableType.id,
                    name: consumableType.name,
                    use_range: [consumableType.pivot.used_at, consumableType.pivot.expired_at]
                })
            })
            if (row.used_at && row.expired_at) {
                info.use_range = [row.used_at, row.expired_at]
            }
            this.getsensors()
            this.sensorInfo = info
            this.sensorInfoShow = true
        },
        onShowDelsensor(row) {
            this.currentDelsensor = row
            this.dialogDelsensor = true
        },
        onDelsensor() {
            this.$util.del('sensors/' + this.currentDelsensor.id).then(() => {
                this.getTableData()
                this.dialogDelsensor = false
            })
        },
        subsensor() {
            this.$refs['sensorInfo'].validate((valid) => {
                if (valid) {
                    delete this.sensorInfo.datum_types
                    delete this.sensorInfo.consumable_types
                    if (!this.sensorInfo.id) {
                        this.$util.post('sensors', this.sensorInfo).then(res => {
                            if(res.code > 0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.sensorInfoShow = false
                            this.showDetailStatus = false
                        })
                    } else {
                        this.$util.put('sensors/' + this.sensorInfo.id, this.sensorInfo).then(res => {
                            if(res.code > 0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.sensorInfoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        resetsensor() {
            this.sensorInfo = {
                name: '',
                en_name: '',
                code: '',
                mac: '',
                color: '',
                version: '',
                type: '',
                device_id: '',
                use_range: [],
                datum_types: [],
                consumable_types: [],
                tempDatumTypes: [],
                tempConsumableTypes: [],
                status: 1
            }
        },
        setsensorData(val) {
            let data = {
                type: val
            }
            if(this.sensorInfo.id) {
                data.id = this.sensorInfo.id
            }
            this.$util.get('ajax/latest_sensor_code', data).then(res=>{
                var str = Array(6-res.max.toString().length)
                str.push(res.max)
                this.sensorInfo.code = res.type_word + str.join('0')
            })
            let datumTypes = []
            let consumableTypes = []
            this.sensor_types.forEach(sensor_type => {
                if (sensor_type.id == val) {
                    sensor_type.datum_types.forEach(sdatumType => {
                        if(sdatumType.type == 1) {
                            if(this.sensorInfo.id) {
                                let flag = false
                                this.sensorInfo.datum_types.forEach(datumType=>{
                                    if(sdatumType.id == datumType.id) {
                                        flag = true
                                        datumTypes.push({
                                            id: sdatumType.id,
                                            name: sdatumType.name,
                                            min: datumType.pivot.min,
                                            max: datumType.pivot.max,
                                            adjust: datumType.pivot.adjust,
                                            decimals: datumType.pivot.decimals
                                        })
                                    }
                                })
                                if(!flag) {
                                    datumTypes.push({
                                        id: sdatumType.id,
                                        name: sdatumType.name,
                                        min: '',
                                        max: '',
                                        adjust: '',
                                        decimals: 2
                                    })
                                }
                            } else{
                                datumTypes.push({
                                    id: sdatumType.id,
                                    name: sdatumType.name,
                                    min: '',
                                    max: '',
                                    adjust: '',
                                    decimals: 2
                                })
                            }
                        }
                    })
                    this.sensorInfo.tempDatumTypes = datumTypes
                    sensor_type.consumable_types.forEach(sconsumableType => {
                        if(this.sensorInfo.id) {
                            let flag = false
                            this.sensorInfo.consumable_types.forEach(consumableType=>{
                                if(sconsumableType.id == consumableType.id) {
                                    flag = true
                                    consumableTypes.push({
                                        id: sconsumableType.id,
                                        name: sconsumableType.name,
                                        use_range: [consumableType.pivot.used_at, consumableType.pivot.expired_at]
                                    })
                                }
                            })
                            if(!flag) {
                                consumableTypes.push({
                                    id: sconsumableType.id,
                                    name: sconsumableType.name,
                                    use_range: [this.$moment().format('yyyy-MM-DD'), this.$moment().add(sconsumableType.month, 'months').format('yyyy-MM-DD')]
                                })
                            }
                        } else{
                            consumableTypes.push({
                                id: sconsumableType.id,
                                name: sconsumableType.name,
                                use_range: [this.$moment().format('yyyy-MM-DD'), this.$moment().add(sconsumableType.month, 'months').format('yyyy-MM-DD')]
                            })
                        }
                    })
                    this.sensorInfo.tempConsumableTypes = consumableTypes
                }
            })
        },
        drawLine() {
            var _this = this
            if(this.chart) {
                this.chart.dispose()
            }
            this.$nextTick(() => {
                const loading = this.$loading({
                    lock: true,
                    text: '数据加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.3)'
                });
                this.$util.get('ajax/datum_histories', {
                    device_id: this.detail.id,
                    range: this.chartRange,
                    datum_type: this.currentDatumType,
                    sensor_ids: this.currentsensorIds
                }).then(res => {
                    loading.close()
                    this.xdata = res.xdata
                    this.series = res.series
                    // let visualMap = {}
                    let option = {
                        color: res.color,
                        title: {
                            text: '设备历史数据查询',
                            left: 20
                        },
                        legend: {
                            data: res.legends
                        },
                        grid: {
                            left: 30,
                            right: 45,
                            bottom: 45,
                            top: 70,
                            containLabel: true
                        },
                        // 鼠标覆盖提示信息
                        tooltip: {
                            trigger: 'axis',
                        },
                        xAxis: {
                            type: 'category',
                            data: this.xdata
                        },
                        yAxis: {
                            type: 'value',
                            name: '单位：' + _this.currentDatumUnit,
                            boundaryGap: [0, '100%'],
                            axisLabel: {
                                formatter: function (value) {
                                    return value.toFixed(2)
                                }
                            },
                        },
                        dataZoom: [{
                                type: 'slider',
                                xAxisIndex: 0,
                                filterMode: 'none'
                            },
                            {
                                type: 'slider',
                                yAxisIndex: 0,
                                filterMode: 'none'
                            },
                            {
                                type: 'inside',
                                xAxisIndex: 0,
                                filterMode: 'none'
                            },
                            {
                                type: 'inside',
                                yAxisIndex: 0,
                                filterMode: 'none'
                            }
                        ],
                        series: this.series
                    }
                    _this.chart = _this.$echarts.init(_this.$refs['chart'])
                    // 绘制图表 
                    _this.chart.setOption(option);
                    _this.chart.on('legendselectchanged', function (params) {
                        let count = 0;
                        let i = 0;
                        let selectedIndex = 0;
                        for (const k in params.selected) {
                            if (params.selected[k]) {
                                selectedIndex = i
                                count++
                            }
                            i++
                        }
                        console.log(selectedIndex)
                        if (count == 1) {
                        //     visualMap = {
                        //         top: 50,
                        //         right: 0,
                        //         pieces: [{
                        //             gt: _this.series[selectedIndex].min,
                        //             lte: _this.series[selectedIndex].max,
                        //             color: '#67C23A'
                        //         }],
                        //         outOfRange: {
                        //             color: '#f90000'
                        //         }
                        //     }
                        //     _this.chart.setOption({
                        //         visualMap: visualMap
                        //     })
                        // } else {
                        //     _this.chart.dispose()
                        //     _this.drawLine()
                        }
                    })
                })
            })
        },
        getNewData() {
            this.$util.get('realtime', {
                device_id: 4,
            }).then(res => {
                return {
                    name: res.time,
                    value: [
                        res.time,
                        res.temp
                    ]
                }
            })
        },
        onChartExport() {
            window.open('https://test.wechatapp.inlems.com/adm/datum_logs_export?sensors=' + this.currentsensorIds.join(',') + '&range=' + this.chartRange)
            this.$message('导出成功')
        }
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}

.sensor-box {
    margin-bottom: 10px;
}

.sensor-name {
    width: 100px;
    margin: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.data-value {
    margin-bottom: 10px;
}

.dialog-seners {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.dialog-sener {
    width: 20%;
}

.dialog-sener-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.button-new-tag {
    margin-left: 10px;
}

.device-header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.device-img {
    width: 60px;
    margin-right: 20px;
    border: #ddd 1px solid;
    padding: 10px;
}

.device-name {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
}

.dialog-sensor {
    display: flex;
    align-items: center;
}

.sensor-img {
    width: 30px;
    height: 30px;
    overflow: hidden;
    display: inline-block;
}

.chart {
    width: 100%;
    height: 400px;
    margin-top: 30px;
}
</style>
