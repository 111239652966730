<template>
    <div class="page">
        <div class="login-box">
            <div class="logo">
                <el-image :src="logo" fit="fill" :lazy="true"></el-image>
            </div>
            <div class="login-form">
                <el-form :model="form" ref="form" :rules="rules" :inline="false" size="normal" class="form-input" :show-message="false" @keyup.enter.native="onSubmit">
                    <el-form-item label="" prop="username">
                        <el-input v-model="form.username" prefix-icon="el-icon-user" size="normal" placeholder="请输入登录账号" class="form-input"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="password">
                        <el-input show-password v-model="form.password" prefix-icon="el-icon-lock" placeholder="请输入登录密码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button round @click="onSubmit" class="btn">立即登录</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            logo: require('@/assets/logo_w.png'),
            form: {
                username: '',
                password: ''
            },
            rules: {
                username: [{
                    required: true,
                    message: '请输入登录账号',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: '请输入登录密码',
                    trigger: 'blur'
                }]
            }
        }
    },
    mounted() {
        if(localStorage.getItem('token'))
        {
            if(this.$moment().diff(localStorage.getItem('loginTime'), 'minutes', true) < 30) {
                this.$router.push('/')
            }
        }
    },
    methods: {
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$util.post('login', this.form).then(res => {
                        if(res.code > 0){
                            this.$message.error(res.msg)
                        }else{
                            localStorage.setItem('adminer', JSON.stringify(res.data.adminer))
                            localStorage.setItem('loginTime', this.$moment().format('yyyy-MM-DD HH:mm:ss'))
                            localStorage.setItem('token', res.data.adminer.token)
                            localStorage.setItem('permissions', res.data.permissions)
                            this.$router.push('/')
                        }
                    })
                } else {
                    this.$message.error('账号密码错误');
                    return false;
                }
            });
        }
    }
}
</script>

<style scoped>
.page{
    height: 100%;
    background-color: #281C72;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-box{
    width: 300px;
}
.logo{
    width: 200px;
    margin: 0 auto 50px;
}
.form-input /deep/ .el-input__inner{
    border-radius: 30px;
    background-color: #2D4D98;
    border: #2D4D98 1px solid;
    color: #fff;
}
.btn{
    width: 100%;
    box-shadow: rgba(255, 255, 255, .2) 0 0 10px;
}
</style>