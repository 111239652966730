<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
html,body{
  margin: 0;
  padding: 0;
  height: 100%;
  color: #333;
  font-size: 14px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.block{
    background-color: #fff;
    border-radius: 10px;
    margin-top: 20px;
}
.block-header{
    padding: 20px;
}
.block-title{
    font-size: 16px;
}
.block-body{
    padding: 20px;
}
.block-nobg{
    background-color: transparent;
    margin-top: 20px;
}
.el-tag+.el-tag{
    margin-left: 10px;
}
.wx-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wx-row-center{
    justify-content: center;
}
.wx-row-wrap{
    flex-wrap: wrap;
}
.col-mini{
  font-size: 12px;
}
</style>
