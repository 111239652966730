<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>消息</el-breadcrumb-item>
            <el-breadcrumb-item>消息模板</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索名称" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
            <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="title" label="名称" width="300"></el-table-column>
            <el-table-column prop="content" label="模板内容"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm placement="top" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row)" class="btn-pop">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" :rules="rules" label-width="120px" :inline="false" size="small">
            <el-form-item label="名称" prop="title">
                <el-input v-model="info.title"></el-input>
            </el-form-item>
            <el-form-item label="模板内容" prop="content">
                <el-input type="textarea" v-model="info.content"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            filter: {
                keyword: ''
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saveType: 1, // 1新增 2编辑
            info: {
                title: '',
                content: '',
            },
            rules: {
                title: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '请填写内容',
                    trigger: 'blur'
                }],
            },
        }
    },
    mounted() {
        this.getTableData()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('warning_templates', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
            this.getCustomerTypes()
        },
        onEdit(row) {
            this.saveType = 2
            this.info = row
            this.info.area = [row.province_id, row.city_id, row.district_id]
            this.infoShow = true
            this.getCustomerTypes()
        },
        onDel(row) {
            console.log(row.id)
        },
        onQuick(id, field, val) {
            this.$util.put('warning_templates/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    if (this.saveType == 1) {
                        this.$util.post('warning_templates', this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    } else {
                        this.$util.put('warning_templates/' + this.info.id, this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        resetInfo() {
            this.info = {
                title: '',
                content: '',
            }
        },
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
</style>
