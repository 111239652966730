import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

const echarts = require('echarts');
Vue.prototype.$echarts = echarts

import router from './router/index.js';
router.beforeEach((to, from, next) => {
    var token = localStorage.getItem('token');
    var permissions = localStorage.getItem('permissions') ? localStorage.getItem('permissions').split(',') : [];
    if (to.meta.requireAuth) {
        if (token == 'undefined' || token == null || token == '' || token == 'null') {
            next({ path: '/login' })
        } else {
            if (to.meta.permissions.length > 0) {
                let flag = false
                permissions.forEach(permission => {
                    if(to.meta.permissions.includes(permission)){
                        flag = true
                    }
                });
                if(flag){
                    next()
                }else{
                    next({path: '/403'})
                }
            }else{
                next()
            }
            if (to.meta.title) {
                document.title = 'INLEMS 管理后台 - ' + to.meta.title
            }
        }
    }
    else {
        next();
    }
})

import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        pageTabs: [{
            name: 'dashboard',
            route: '/dashboard',
            meta: {
                title: '管理首页'
            },
        }],
        activeIndex: '/dashboard',
        permssions: [],
        menus: []
    },
    mutations: {
        // 添加tabs
        add_tabs(state, data) {
            this.state.pageTabs.push(data);
        },
        // 删除tabs
        delete_tabs(state, route) {
            let index = 0;
            for (let option of state.pageTabs) {
                if (option.route === route) {
                    break;
                }
                index++;
            }
            this.state.pageTabs.splice(index, 1);
        },
        // 设置当前激活的tab
        set_active_index(state, index) {
            this.state.activeIndex = index;
        },
        set_permssions(state, permssions) {
            this.state.permssions = permssions
        },
        set_menus(state, menus) {
            this.state.menus = menus
        }
    },
    actions: {
        permssions({ commit }) {
            commit('permssions')
        }
    }
})

import moment from 'moment'
Vue.prototype.$moment = moment 

import util from './utils/util'
Vue.prototype.$util = util

new Vue({
  render: h => h(App),
  store,
  router,
  moment,
}).$mount('#app')
