<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>设备</el-breadcrumb-item>
            <el-breadcrumb-item>传感器类型</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索名称" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
            <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="thumb" label="图片" align="center" width="80">
                <template slot-scope="scope">
                    <div class="img">
                        <el-image :src="scope.row.thumb" fit="fill" :lazy="true" width="60"></el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="datum_types" label="监测数据类型" width="400">
                <template slot-scope="scope">
                    <el-tag :type="item.type == 1 ? 'success' : 'info'" size="mini" effect="dark" v-for="(item,i) in scope.row.datum_types" :key="i">{{item.name}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="datum_types" label="耗材类型" width="400">
                <template slot-scope="scope">
                    <el-tag type="success" size="mini" effect="dark" v-for="(item,i) in scope.row.consumable_types" :key="i">{{item.name}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm v-if="scope.row.sensors_count == 0" placement="top" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row)" class="btn-pop">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        
        <el-form :model="info" ref="info" :rules="rules" label-width="200px" :inline="false" size="small">
            <el-form-item label="名称" prop="name">
                <el-input v-model="info.name"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="thumb">
                <el-upload class="img-uploader" :headers="headers" :action="uploadUrl" :show-file-list="false" list-type="picture-card" :before-upload="beforeUpload" :on-success="uploadLogoSuccess">
                    <el-image v-if="info.thumb" :src="info.thumb" class="thumb" fit="fill" :lazy="true"></el-image>
                    <i v-else class="el-icon-plus img-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="传感器编码前缀" prop="word">
                <el-input v-model="info.word"></el-input>
            </el-form-item>
            <el-form-item label="数据类型" prop="datum_types">
                <el-select v-model="info.tempDatumTypes" placeholder="选择数据类型" multiple clearable filterable @change="setDatumTypes">
                    <el-option v-for="item in datum_types" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="耗材类型" prop="consumable_types">
                <el-select v-model="info.tempConsumableTypes" placeholder="选择耗材类型" multiple clearable filterable>
                    <el-option v-for="item in consumable_types" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-divider content-position="center">数据类型配置</el-divider>
            <div>
                <el-form-item :label="datumType.name" v-for="(datumType, di) in this.info.tempDatumTypeSettings" :key="datumType.id" :prop="'tempDatumTypeSettings.' + di + '.id'">
                    <el-input class="data-value" v-model="datumType.start_char" placeholder="起始位置" clearable>
                        <template slot="prepend">MQTT数据位置</template>
                    </el-input>
                    <el-input class="data-value" v-model="datumType.length" placeholder="数据长度" clearable>
                        <template slot="prepend">MQTT数据长度</template>
                    </el-input>
                    <el-input class="data-value" v-model="datumType.sort" placeholder="数据排序" clearable>
                        <template slot="prepend">展示排序</template>
                    </el-input>
                    <el-radio v-model="datumType.status" :label="1">小程序端显示</el-radio>
                    <el-radio v-model="datumType.status" :label="2">小程序端隐藏</el-radio>
                </el-form-item>
            </div>
            <el-divider content-position="center">MQTT数据配置</el-divider>
            <el-form-item label="主题标识" prop="label">
                <el-input v-model="info.label"></el-input>
            </el-form-item>
            <el-form-item label="MAC地址位置" prop="start_mac">
                <el-input v-model="info.start_mac"></el-input>
            </el-form-item>
            <el-form-item label="基站版本位置" prop="start_ver_device">
                <el-input v-model="info.start_ver_device"></el-input>
            </el-form-item>
            <el-form-item label="基站版本数据长度" prop="start_ver_device_length">
                <el-input v-model="info.start_ver_device_length"></el-input>
            </el-form-item>
            <el-form-item label="传感器版本位置" prop="start_ver_sensor">
                <el-input v-model="info.start_ver_sensor"></el-input>
            </el-form-item>
            <el-form-item label="传感器数据长度" prop="start_ver_sensor_length">
                <el-input v-model="info.start_ver_sensor_length"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            filter: {
                keyword: ''
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saveType: 1, // 1新增 2编辑
            info: {
                name: '',
                label: '',
                word: '',
                thumb: '',
                start_mac: 0,
                start_ver_device: 0,
                start_ver_device_length: 0,
                start_ver_sensor: 0,
                start_ver_sensor_length: 0,
                tempDatumTypes: [],
                tempDatumTypeSettings: [],
                tempConsumableTypes: []
            },
            rules: {
                title: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'blur'
                }],
                word: [{
                    required: true,
                    message: '请填写传感器编码前缀',
                    trigger: 'blur'
                }],
                label: [{
                    required: true,
                    message: '请填写MQTT主题标识',
                    trigger: 'blur'
                }],
                start_mac: [{
                    required: true,
                    message: '请填写MAC地址位置',
                    trigger: 'blur'
                }],
                start_ver_device: [{
                    required: true,
                    message: '请填写基站版本位置',
                    trigger: 'blur'
                }],
                start_ver_device_length: [{
                    required: true,
                    message: '请填写基站版本数据长度',
                    trigger: 'blur'
                }],
                start_ver_sensor: [{
                    required: true,
                    message: '请填写传感器版本位置',
                    trigger: 'blur'
                }],
                start_ver_sensor_length: [{
                    required: true,
                    message: '请填写传感器数据长度',
                    trigger: 'blur'
                }],
            },
            regions: [],
            uploadUrl: 'adm/upload',
            headers: {
                Authorization:'Bearer '+localStorage.getItem('token')
            },
            datum_types: [],
            consumable_types: []
        }
    },
    mounted() {
        this.getTableData()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('sensor_types', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
            this.getDatumTypes()
            this.getConsumableTypes()
        },
        onEdit(row) {
            this.saveType = 2
            let info = {
                id: row.id,
                name: row.name,
                label: row.label,
                word: row.word,
                thumb: row.thumb,
                start_mac: row.start_mac,
                start_ver_device: row.start_ver_device,
                start_ver_device_length: row.start_ver_device_length,
                start_ver_sensor: row.start_ver_sensor,
                start_ver_sensor_length: row.start_ver_sensor_length,
                datum_types: row.datum_types,
                tempDatumTypes: [],
                tempDatumTypeSettings: [],
                tempConsumableTypes: [],
            }
            row.datum_types.forEach(datum_type => {
                info.tempDatumTypes.push(datum_type.id)
                info.tempDatumTypeSettings.push({
                    id: datum_type.id,
                    name: datum_type.name,
                    start_char: datum_type.pivot.start_char,
                    sort: datum_type.pivot.sort,
                    length: datum_type.pivot.length,
                    model: datum_type.pivot.model,
                    status: datum_type.pivot.status,
                })
            });
            row.consumable_types.forEach(consumable_type => {
                info.tempConsumableTypes.push(consumable_type.id)
            });
            this.info = info
            this.infoShow = true
            this.getDatumTypes()
            this.getConsumableTypes()
        },
        onDel(row) {
            this.$util.del('sensor_types/' + row.id).then(res => {
                if(res.code > 0) {
                    this.$message.error(res.msg);
                    return false;
                }
                this.$message({
                    type: 'success',
                    message: res.msg
                });
                this.getTableData()
            })
        },
        onQuick(id, field, val) {
            this.$util.put('sensor_types/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    if (this.saveType == 1) {
                        this.$util.post('sensor_types', this.info).then(res => {
                            if(res.code > 0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.infoShow = false
                        })
                    } else {
                        this.$util.put('sensor_types/' + this.info.id, this.info).then(res => {
                            if(res.code > 0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.infoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        getDatumTypes() {
            this.$util.get('ajax/datum_types').then(res => {
                this.datum_types = res
            })
        },
        getConsumableTypes() {
            this.$util.get('ajax/consumable_types').then(res => {
                this.consumable_types = res
            })
        },
        resetInfo() {
            this.info = {
                name: '',
                label: '',
                word: '',
                thumb: '',
                start_mac: 0,
                start_ver_device: 0,
                start_ver_device_length: 0,
                start_ver_sensor: 0,
                start_ver_sensor_length: 0,
                tempDatumTypes: [],
                tempDatumTypeSettings: [],
                tempConsumableTypes: []
            }
        },
        beforeUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error('上传图片只能是 JPG,PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
        uploadLogoSuccess(res) {
            this.info.thumb = res.data;
        },
        setDatumTypes( chooseDatumTypes ) {
            console.log(chooseDatumTypes)
            let tempDatumTypeSettings = this.info.tempDatumTypeSettings
            console.log(tempDatumTypeSettings)
            // 先检查设置项中的数据类型是否在已选类型中存在，不存在则先清除
            tempDatumTypeSettings.forEach((DatumType,i)=>{
                if(chooseDatumTypes.indexOf(DatumType.id) < 0) {
                    tempDatumTypeSettings.splice(i, 1)
                }
            })
            let orignalDatumTypes = this.info.id ? this.info.datum_types : []
            chooseDatumTypes.forEach(item=>{
                let had = false
                tempDatumTypeSettings.forEach(DatumType=>{
                    if(DatumType.id == item) {
                        had = true
                    }
                })
                if(!had) {
                    this.datum_types.forEach(datumType => {
                        if(datumType.id == item) {
                            let flag = false
                            orignalDatumTypes.forEach(old=>{
                                if(old.id == item) {
                                    flag = true
                                    this.info.tempDatumTypeSettings.push({
                                        id: datumType.id,
                                        name: datumType.name,
                                        start_char: old.pivot.start_char,
                                        sort: old.pivot.sort,
                                        length: old.pivot.length,
                                        model: old.pivot.model,
                                        status: old.pivot.status,
                                    })
                                }
                            })
                            if(!flag) {
                                this.info.tempDatumTypeSettings.push({
                                    id: datumType.id,
                                    name: datumType.name,
                                    start_char: '',
                                    sort: '',
                                    length: '',
                                    model: '',
                                    status: 1,
                                })
                            }
                        }
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
.data-value {
    margin-bottom: 10px;
}
</style>
