<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>客户</el-breadcrumb-item>
            <el-breadcrumb-item>微信用户</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-select v-model="filter.customer" placeholder="客户名称搜索" size="mini" clearable filterable>
                        <el-option v-for="customer in customers" :key="customer.id" :label="customer.name" :value="customer.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索姓名 / 昵称 / 手机号" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                    <el-button type="success" @click="onExport" size="mini">导出</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="avatar" label="头像" align="center" width="80">
                <template slot-scope="scope">
                    <div class="img">
                        <el-image :src="scope.row.avatar" fit="fill" :lazy="true" width="60"></el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称"></el-table-column>
            <el-table-column prop="realname" label="真实名称" width="80"></el-table-column>
            <el-table-column prop="customer.name" label="绑定客户" align="center" width="300">
                <template slot-scope="scope">
                    <div class="sensor-box" v-for="customer in scope.row.customers" :key="customer.id">
                        <el-tag closable @close="onShowDelCustomer(customer, scope.row)">{{customer.name}}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="mobile" label="联系信息" width="300">
                <template slot-scope="scope">
                    <div><i class="el-icon-mobile"></i> {{scope.row.phone}}</div>
                    <div><i class="el-icon-paperclip"></i> {{scope.row.email}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" label="注册日期" width="120">
                <template slot-scope="scope">
                    {{scope.row.created_at.substring(0, 10)}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm placement="top" v-if="scope.row.customers.length == 0" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row)" class="btn-pop">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                    <el-button size="mini" type="danger" v-else @click="unbind(scope.row.id)">解绑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" :rules="rules" label-width="120px" :inline="false" size="small">
            <el-form-item label="所属客户" prop="customer_id">
                <el-select v-model="customerIds" placeholder="选择客户类型" clearable filterable multiple @change="setCustomers">
                    <el-option v-for="item in customers" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="头像" prop="avatar">
                <el-image :src="info.avatar" fit="fill" :lazy="true" width="30"></el-image>
            </el-form-item>
            <el-form-item label="昵称" prop="nickname">
                <el-input v-model="info.nickname" disabled></el-input>
            </el-form-item>
            <el-form-item label="真实姓名" prop="realname">
                <el-input v-model="info.realname"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
                <el-input v-model="info.phone"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input v-model="info.email"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>

    <!-- 确认解除绑定的客户 -->
    <el-dialog title="解除绑定" :visible.sync="dialogDelCustomer" width="30%" v-if="currentDelCustomer">
        <span>确认解除【{{currentMember.realname}}】与【{{currentDelCustomer.name}}】的绑定关系吗？</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogDelCustomer = false">取 消</el-button>
            <el-button type="primary" @click="onDelCustomer">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        var validatorPhone = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('手机号不能为空'))
            } else if(!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value)) {
                callback(new Error('手机号格式错误'))
            } else{
                callback();
            }
        }
        return {
            filter: {
                keyword: '',
                customer: '',
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saveType: 1, // 1新增 2编辑
            info: {
                nickname: '',
                realname: '',
                phone: '',
                avatar: '',
                email: '',
                customerIds: [],
            },
            rules: {
                realname: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'blur'
                }],
                phone: [{
                    required: true,
                    validator: validatorPhone,
                    message: '请填写正确的手机号',
                    trigger: 'blur'
                }],
            },
            customerIds: [],
            customers: [],
            currentMember: null,
            currentDelCustomer: null,
            dialogDelCustomer: false,
        }
    },
    mounted() {
        this.getTableData()
        this.getCustomers()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('members', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
        },
        onEdit(row) {
            this.saveType = 2
            this.info = {
                id: row.id,
                nickname: row.nickname,
                realname: row.realname,
                phone: row.phone,
                avatar: row.avatar,
                email: row.email,
            }
            let customerIds = []
            row.customers.forEach(customer => {
                customerIds.push(customer.id)
            });
            this.customerIds = customerIds
            this.infoShow = true
        },
        onDel(row) {
            this.$util.del('members/' + row.id).then(() => {
                this.getTableData()
            })
        },
        onQuick(id, field, val) {
            this.$util.put('members/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        unbind(id) {
            this.$confirm('此操作将解除该微信绑定的客户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$util.put('members/' + id, {
                    customer_id: 0
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '解绑成功!'
                    });
                    this.getTableData()
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消解绑'
                });
            });
        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    this.info.customerIds = this.customerIds
                    if (this.saveType == 1) {
                        this.$util.post('members', this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    } else {
                        this.$util.put('members/' + this.info.id, this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        resetInfo() {
            this.info = {
                nickname: '',
                realname: '',
                phone: '',
                avatar: '',
                email: '',
                customer_id: '',
            }
        },
        getCustomers() {
            this.$util.get('ajax/customers').then(res => {
                this.customers = res
                this.customers.unshift({
                    id:0,
                    name: '请选择所属客户'
                })
            })
        },
        onShowDelCustomer(customer, member) {
            this.currentDelCustomer = customer
            this.currentMember = member
            this.dialogDelCustomer = true
        },
        onDelCustomer() {
            this.$util.del('members/'+this.currentMember.id+'/del_customer', {
                customerId: this.currentDelCustomer.id
            }).then(() => {
                this.getTableData()
                this.dialogDelCustomer = false
            })
        },
        onExport() {
            window.open('https://wechatapp.inlems.com/adm/members_export')
        },
        setCustomers() {
            console.log(this.info)
        }
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
.button-new-tag {
    margin-top: 10px;
}
</style>
