import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

export default new VueRouter({
    saveScrollPosition: true,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: 'main',
            meta: { requireAuth: true, title: 'INLEMS 管理平台' },
            redirect: '/dashboard',
            component: resolve => void (require(['../views/Main.vue'], resolve)),
            children: [
                {
                    path: '/dashboard',
                    component: resolve => void (require(['../views/Dashboard.vue'], resolve)),
                    name: 'dashboard',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '管理首页'
                    }
                },
                {
                    path: '/customers',
                    component: resolve => void (require(['../views/Customer.vue'], resolve)),
                    name: 'customers',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '客户列表'
                    }
                },
                {
                    path: '/senser_data',
                    component: resolve => void (require(['../views/SenserDatum.vue'], resolve)),
                    name: 'senser_data',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '传感器参数配置'
                    }
                },
                {
                    path: '/adminer_datum_export_logs',
                    component: resolve => void (require(['../views/AdminerDatumExportLog.vue'], resolve)),
                    name: 'adminer_datum_export_logs',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '后台导出数据记录'
                    }
                },
                {
                    path: '/adminers',
                    component: resolve => void (require(['../views/Adminer.vue'], resolve)),
                    name: 'adminers',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '管理员'
                    }
                },
                {
                    path: '/backup_logs',
                    component: resolve => void (require(['../views/BackupLog.vue'], resolve)),
                    name: 'backup_logs',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '备份记录'
                    }
                },
                {
                    path: '/customer_types',
                    component: resolve => void (require(['../views/CustomerType.vue'], resolve)),
                    name: 'customer_types',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '客户类型'
                    }
                },
                {
                    path: '/datum_logs',
                    component: resolve => void (require(['../views/DatumLog.vue'], resolve)),
                    name: 'datum_logs',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '数据记录'
                    }
                },
                {
                    path: '/datum_types',
                    component: resolve => void (require(['../views/DatumType.vue'], resolve)),
                    name: 'datum_types',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '数据类型'
                    }
                },
                {
                    path: '/device_types',
                    component: resolve => void (require(['../views/DeviceType.vue'], resolve)),
                    name: 'device_types',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '设备类型'
                    }
                },
                {
                    path: '/devices',
                    component: resolve => void (require(['../views/Device.vue'], resolve)),
                    name: 'devices',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '设备'
                    }
                },
                {
                    path: '/member_datum_export_logs',
                    component: resolve => void (require(['../views/MemberDatumExportLog.vue'], resolve)),
                    name: 'member_datum_export_logs',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '客户数据导出记录'
                    }
                },
                {
                    path: '/members',
                    component: resolve => void (require(['../views/Member.vue'], resolve)),
                    name: 'members',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '微信用户'
                    }
                },
                {
                    path: '/menus',
                    component: resolve => void (require(['../views/Menu.vue'], resolve)),
                    name: 'menus',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '后台菜单'
                    }
                },
                {
                    path: '/operation_logs',
                    component: resolve => void (require(['../views/OperationLog.vue'], resolve)),
                    name: 'operation_logs',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '操作日志'
                    }
                },
                {
                    path: '/permissions',
                    component: resolve => void (require(['../views/Permission.vue'], resolve)),
                    name: 'permissions',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '权限管理'
                    }
                },
                {
                    path: '/roles',
                    component: resolve => void (require(['../views/Role.vue'], resolve)),
                    name: 'permissions',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '角色管理'
                    }
                },
                {
                    path: '/senser_types',
                    component: resolve => void (require(['../views/SenserType.vue'], resolve)),
                    name: 'senser_types',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '传感器类型'
                    }
                },
                {
                    path: '/sensers',
                    component: resolve => void (require(['../views/Senser.vue'], resolve)),
                    name: 'sensers',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '传感器'
                    }
                },
                {
                    path: '/servers',
                    component: resolve => void (require(['../views/Server.vue'], resolve)),
                    name: 'servers',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '服务器'
                    }
                },
                {
                    path: '/warning_configs',
                    component: resolve => void (require(['../views/WarningConfig.vue'], resolve)),
                    name: 'warning_configs',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '提醒配置'
                    }
                },
                {
                    path: '/warning_logs',
                    component: resolve => void (require(['../views/WarningLog.vue'], resolve)),
                    name: 'warning_logs',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '提醒记录'
                    }
                },
                {
                    path: '/warning_templates',
                    component: resolve => void (require(['../views/WarningTemplate.vue'], resolve)),
                    name: 'warning_templates',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '提醒消息模板'
                    }
                },
                {
                    path: '/consumables',
                    component: resolve => void (require(['../views/Consumable.vue'], resolve)),
                    name: 'consumables',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '耗材管理'
                    }
                },
                {
                    path: '/consumable_types',
                    component: resolve => void (require(['../views/ConsumableType.vue'], resolve)),
                    name: 'consumable_types',
                    meta: {
                        requireAuth: true,
                        keepAlive: true,
                        permissions: [],
                        title: '耗材类型'
                    }
                },
            ]
        },
        {
            path: '/login',
            component: resolve => void (require(['../views/Login.vue'], resolve)),
            name: 'Login'
        },
    ]
})