<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>客户</el-breadcrumb-item>
            <el-breadcrumb-item>服务器</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索客户名称" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
            <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="code" label="服务器编码"></el-table-column>
            <el-table-column prop="customer.name" label="客户"></el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <el-popconfirm placement="top" confirm-button-text='确定' cancel-button-text='取消' icon-color="red" title="确定修改状态吗？" @cancel="scope.row.status = (scope.row.status == 1) ? 0 : 1" @confirm="changeStatus(scope.row)" class="btn-pop">
                        <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" slot="reference"></el-switch>
                    </el-popconfirm>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" label="添加日期">
                <template slot-scope="scope">
                    {{scope.row.created_at.substring(0, 10)}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm v-if="scope.row.devices_count == 0" placement="top" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row)" class="btn-pop">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" :rules="rules" label-width="120px" :inline="false" size="small">
            <el-form-item label="客户" prop="customer_id">
                <el-select v-model="info.customer_id" placeholder="选择所属客户" clearable filterable>
                    <el-option v-for="item in customers" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="编码" prop="code">
                <el-input v-model="info.code"></el-input>
            </el-form-item>
            <el-form-item label="使用时间段" prop="use_range">
                <el-date-picker v-model="info.use_range" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="请选择开始日期" end-placeholder="请选择截至日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="状态" prop="status" v-if="info.status !== undefined">
                <el-switch v-model="info.status" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            filter: {
                keyword: ''
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saveType: 1, // 1新增 2编辑
            info: {
                customer_id: '',
                code: '',
                use_range: []
            },
            rules: {
                customer_id: [{
                    required: true,
                    message: '请选择客户',
                    trigger: 'change'
                }],
                code: [{
                    required: true,
                    message: '请填写编码',
                    trigger: 'blur'
                }],
            },
            customers: []
        }
    },
    mounted() {
        this.getTableData()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('servers', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
            let use_range = [this.$moment().format('yyyy-MM-DD'), this.$moment().add(1, 'years').format('yyyy-MM-DD')]
            this.info.use_range = use_range
            this.getCustomers()
        },
        onEdit(row) {
            this.saveType = 2
            let use_range = [row.start_date, row.end_date]
            this.info = {
                id: row.id,
                customer_id: row.id,
                code: row.code,
                use_range: use_range
            }
            this.infoShow = true
            this.getCustomers()
        },
        onDel(row) {
            this.$util.del('servers/' + row.id).then(res => {
                if(res.code > 0) {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                    return false
                }
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.getTableData()
            })
        },
        onQuick(id, field, val) {
            this.$util.put('servers/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    if (this.saveType == 1) {
                        this.$util.post('servers', this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    } else {
                        this.$util.put('servers/' + this.info.id, this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        resetInfo() {
            this.info = {
                customer_id: '',
                code: '',
                use_range: []
            }
        },
        changeStatus(row) {
            this.$util.put('servers/status', {
                id: row.id
            }).then(() => {
                this.getTableData()
            })
        },
        getCustomers() {
            this.$util.get('ajax/customers').then(res => {
                this.customers = res
            })
        },
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
</style>
