<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>设备</el-breadcrumb-item>
            <el-breadcrumb-item>设备类型</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索名称" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
            <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="thumb" label="图片" align="center" width="80">
                <template slot-scope="scope">
                    <div class="img">
                        <el-image :src="scope.row.thumb" fit="fill" :lazy="true" width="60"></el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm v-if="scope.row.devices_count == 0" placement="top" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row.id)" class="btn-pop">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" :rules="rules" label-width="120px" :inline="false" size="small">
            <el-form-item label="名称" prop="name">
                <el-input v-model="info.name"></el-input>
            </el-form-item>
            <el-form-item label="英文名称" prop="en_name">
                <el-input v-model="info.en_name"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="thumb">
                <el-upload class="img-uploader" :headers="headers" :action="uploadUrl" :show-file-list="false" list-type="picture-card" :before-upload="beforeUpload" :on-success="uploadthumbSuccess">
                    <el-image v-if="info.thumb" :src="info.thumb" class="thumb" fit="fill" :lazy="true"></el-image>
                    <i v-else class="el-icon-plus img-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="描述" prop="description">
                <el-input type="textarea" v-model="info.description"></el-input>
            </el-form-item>
            <el-form-item label="英文描述" prop="em_description">
                <el-input type="textarea" v-model="info.en_description"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            filter: {
                keyword: ''
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saveType: 1, // 1新增 2编辑
            info: {
                name: '',
                en_name: '',
                thumb: '',
                description: '',
                en_description: '',
            },
            rules: {
                name: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'blur'
                }],
                en_name: [{
                    required: true,
                    message: '请填写英文名称',
                    trigger: 'blur'
                }],
            },
            regions: [],
            uploadUrl: 'adm/upload',
            headers: {
                Authorization:'Bearer '+localStorage.getItem('token')
            },
            customer_types: [],
        }
    },
    mounted() {
        this.getTableData()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('device_types', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
        },
        onEdit(row) {
            this.saveType = 2
            this.info = {
                id: row.id,
                name: row.name,
                en_name: row.en_name,
                thumb: row.thumb,
                description: row.description,
                en_description: row.en_description,
            }
            this.infoShow = true
        },
        onDel(id) {
            this.$util.del('device_types/' + id).then(res => {
                if(res.code > 0) {
                    this.$message.error(res.msg);
                    return false;
                }
                this.$message({
                    type: 'success',
                    message: res.msg
                });
                this.getTableData()
            })
        },
        onQuick(id, field, val) {
            this.$util.put('device_types/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    if (this.saveType == 1) {
                        this.$util.post('device_types', this.info).then(res => {
                            if(res.code>0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.infoShow = false
                        })
                    } else {
                        this.$util.put('device_types/' + this.info.id, this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        resetInfo() {
            this.info = {
                name: '',
                en_name: '',
                thumb: '',
                description: '',
                en_description: '',
            }
        },
        changeStatus(row) {
            this.$util.put('device_types/status', {
                id: row.id
            }).then(() => {
                this.getTableData()
            })
        },
        beforeUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error('上传图片只能是 JPG,PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
        uploadthumbSuccess(res) {
            this.info.thumb = res.data;
        },
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
</style>
