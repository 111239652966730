<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>客户管理</el-breadcrumb-item>
            <el-breadcrumb-item>客户</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索名称" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
            <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="logo" label="图片" align="center" width="80">
                <template slot-scope="scope">
                    <div class="img">
                        <el-image :src="scope.row.logo" fit="fill" :lazy="true" width="60"></el-image>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="code" label="客户编码"></el-table-column>
            <el-table-column prop="customer_type.name" label="客户类型" align="center"></el-table-column>
            <el-table-column prop="contact" label="联系信息" width="300">
                <template slot-scope="scope">
                    <div><i class="el-icon-user"></i> {{scope.row.contact}}</div>
                    <div><i class="el-icon-mobile"></i> {{scope.row.phone}}</div>
                    <div v-if="scope.row.province"><i class="el-icon-place"></i> {{scope.row.province.sort_name}} {{scope.row.city.sort_name}} {{scope.row.district.sort_name}} {{scope.row.address}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <el-popconfirm placement="top" confirm-button-text='确定' cancel-button-text='取消' icon-color="red" title="确定修改状态吗？" @cancel="scope.row.status = (scope.row.status == 1) ? 0 : 1" @confirm="changeStatus(scope.row)" class="btn-pop">
                        <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" slot="reference"></el-switch>
                    </el-popconfirm>
                </template>
            </el-table-column>
            <el-table-column prop="created_at" label="添加日期">
                <template slot-scope="scope">
                    {{scope.row.created_at.substring(0, 10)}}
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
                <template slot-scope="scope">
                    <el-button size="mini" type="success" @click="setMsg(scope.row)">消息通知设置</el-button>
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm v-if="scope.row.devices_count == 0" placement="top" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row)" class="btn-pop">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" :rules="rules" label-width="120px" :inline="false" size="small">
            <el-form-item label="客户类型" prop="type">
                <el-select v-model="info.type" placeholder="选择客户类型" clearable filterable>
                    <el-option v-for="item in customer_types" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input v-model="info.name"></el-input>
            </el-form-item>
            <el-form-item label="编码" prop="code">
                <el-input v-model="info.code"></el-input>
            </el-form-item>
            <el-form-item label="LOGO" prop="logo">
                <el-upload class="img-uploader" :headers="headers" :action="uploadUrl" :show-file-list="false" list-type="picture-card" :before-upload="beforeUpload" :on-success="uploadLogoSuccess">
                    <el-image v-if="info.logo" :src="info.logo" class="thumb" fit="fill" :lazy="true"></el-image>
                    <i v-else class="el-icon-plus img-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="联系人" prop="contact">
                <el-input v-model="info.contact"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
                <el-input v-model="info.phone"></el-input>
            </el-form-item>
            <el-form-item label="地区" prop="area">
                <el-cascader v-model="info.area" filterable :options="regions" :props="{ expandTrigger: 'hover' }"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
                <el-input type="textarea" v-model="info.address"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status" v-if="info.status !== undefined">
                <el-switch v-model="info.status" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item label="客服电话" prop="servicer_mobile">
                <el-input v-model="info.servicer_mobile"></el-input>
            </el-form-item>
            <el-form-item label="工程师电话" prop="engineer_mobile">
                <el-input v-model="info.engineer_mobile"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>

    <el-dialog title="消息通知设置" :visible.sync="dialogMessage" width="30%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" label-width="120px" :inline="false" size="small">
            <el-form-item label="时间间隔(分钟)" prop="frequency">
                <el-input v-model="info.msg_frequency" maxlength="3"></el-input>
            </el-form-item>
            <el-form-item label="时间段" prop="range">
                <el-time-picker value-format="HH:mm:ss" v-model="info.msg_range" is-range arrow-control range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-time-picker>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="dialogMessage = false">取消</el-button>
            <el-button type="primary" @click="subMsg" :loading="saving">提交</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            filter: {
                keyword: ''
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saving: false,
            saveType: 1, // 1新增 2编辑
            info: {
                type: 1,
                name: '',
                code: '',
                logo: '',
                contact: '',
                phone: '',
                area: [],
                address: '',
                servicer_mobile: '',
                engineer_mobile: '',
                msg_frequency: '',
                msg_range: ['00:00:00', '23:59:59']
            },
            rules: {
                name: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: '请填写编码',
                    trigger: 'blur'
                }],
                contact: [{
                    required: true,
                    message: '请填写联系人',
                    trigger: 'blur'
                }],
                phone: [{
                    required: true,
                    message: '请填写联系人电话',
                    trigger: 'blur'
                }],
                area: [{
                    required: true,
                    message: '请选择所在地区',
                    trigger: 'change'
                }],
                address: [{
                    required: true,
                    message: '请填写详细地址',
                    trigger: 'blur'
                }],
                servicer_mobile: [{
                    required: true,
                    message: '请填写客服电话',
                    trigger: 'blur'
                }],
                engineer_mobile: [{
                    required: true,
                    message: '请填写工程师电话',
                    trigger: 'blur'
                }],
            },
            regions: [],
            uploadUrl: 'adm/upload',
            headers: {
                Authorization:'Bearer '+localStorage.getItem('token')
            },
            customer_types: [],
            dialogMessage: false
        }
    },
    mounted() {
        this.getTableData()
        this.getRegions()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('customers', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
            this.getCustomerTypes()
        },
        onEdit(row) {
            this.saveType = 2
            this.info = {
                id: row.id,
                type: row.type,
                name: row.name,
                code: row.code,
                logo: row.logo,
                contact: row.contact,
                phone: row.phone,
                address: row.address,
                servicer_mobile: row.servicer_mobile,
                engineer_mobile: row.engineer_mobile,
                msg_frequency: row.msg_frequency,
                msg_range: row.msg_range
            }
            this.info.area = [row.province, row.city, row.district]
            this.infoShow = true
            this.getCustomerTypes()
        },
        onDel(row) {
            this.$util.del('customers/' + row.id).then(res => {
                if(res.code > 0) {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                    return false
                }
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.getTableData()
            })
        },
        onQuick(id, field, val) {
            this.$util.put('customers/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        setMsg(row) {
            this.saveType = 2
            this.info = {
                id: row.id,
                msg_frequency: row.msg_frequency,
                msg_range: row.msg_range
            }
            this.dialogMessage = true
        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    this.saving = true
                    if (this.saveType == 1) {
                        this.$util.post('customers', this.info).then(res => {
                            if(res.code > 0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.infoShow = false
                            this.dialogMessage = false
                            this.saving = false
                        })
                    } else {
                        this.$util.put('customers/' + this.info.id, this.info).then(res => {
                            if(res.code > 0) {
                                this.$message.error(res.msg)
                                return false
                            }
                            this.getTableData()
                            this.infoShow = false
                            this.dialogMessage = false
                            this.saving = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        subMsg() {
            this.$util.put('customers/notify/' + this.info.id, this.info).then(res => {
                if(res.code > 0) {
                    this.$message.error(res.msg)
                    return false
                }
                this.getTableData()
                this.dialogMessage = false
                this.saving = false
            })
        },
        resetInfo() {
            this.info = {
                type: 1,
                name: '',
                code: '',
                logo: '',
                contact: '',
                phone: '',
                area: [],
                address: '',
                servicer_mobile: '',
                engineer_mobile: '',
                msg_frequency: '',
                msg_range: ['00:00:00', '23:59:59']
            }
        },
        changeStatus(row) {
            this.$util.put('customers/status', {
                id: row.id
            }).then(() => {
                this.getTableData()
            })
        },
        getRegions() {
            this.$util.get('ajax/areas').then(res => {
                this.regions = res
            })
        },
        getCustomerTypes() {
            this.$util.get('ajax/customer_types').then(res => {
                this.customer_types = res
            })
        },
        beforeUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error('上传图片只能是 JPG,PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
        uploadLogoSuccess(res) {
            this.info.logo = res.data;
        },
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
</style>
