<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>设备</el-breadcrumb-item>
            <el-breadcrumb-item>数据类型</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="18">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="filter.keyword" placeholder="搜索名称" size="mini" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-col>
        <el-col :span="6" style="text-align: right;">
            <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onCreate">新增</el-button>
        </el-col>
    </el-row>

    <div class="row-table">
        <el-table :data="tableData" style="width: 100%; margin-bottom: 20px;">
            <el-table-column type="index" label="序号" width="50px"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="explain" label="说明"></el-table-column>
            <el-table-column prop="unit" label="单位"></el-table-column>
            <el-table-column prop="type" label="分类">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.type == 1" effect="dark" size="small" type="success">监测指标</el-tag>
                    <el-tag v-else effect="dark" size="small" type="info">设备信息</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="onEdit(scope.row)">编辑</el-button>
                    <el-popconfirm v-if="scope.row.sensers_count == 0" placement="top" confirm-button-text='删除' cancel-button-text='取消' icon-color="red" title="确定删除吗？" @confirm="onDel(scope.row)" class="btn-pop">
                        <el-button type="danger" size="mini" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination background layout="prev, pager, next,total" :page-count="maxpage" :page-size="perpage" :total="total" @current-change="pageChange"></el-pagination>
    </div>

    <el-dialog title="编辑信息" :visible.sync="infoShow" width="50%" @close="resetInfo" :close-on-click-modal="false">
        <el-form :model="info" ref="info" :rules="rules" label-width="120px" :inline="false" size="small">
            <el-form-item label="名称" prop="name">
                <el-input v-model="info.name"></el-input>
            </el-form-item>
            <el-form-item label="正常图标" prop="icon1">
                <el-upload class="img-uploader" :headers="headers" :action="uploadUrl" :show-file-list="false" list-type="picture-card" :before-upload="beforeUpload" :on-success="uploadIcon1Success">
                    <el-image v-if="info.icon1" :src="info.icon1" class="thumb" fit="fill" :lazy="true"></el-image>
                    <i v-else class="el-icon-plus img-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="报警图标" prop="icon2">
                <el-upload class="img-uploader" :headers="headers" :action="uploadUrl" :show-file-list="false" list-type="picture-card" :before-upload="beforeUpload" :on-success="uploadIcon2Success">
                    <el-image v-if="info.icon2" :src="info.icon2" class="thumb" fit="fill" :lazy="true"></el-image>
                    <i v-else class="el-icon-plus img-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="说明" prop="explain">
                <el-input v-model="info.explain"></el-input>
            </el-form-item>
            <el-form-item label="单位" prop="unit">
                <el-input v-model="info.unit"></el-input>
            </el-form-item>
            <el-form-item label="分类" prop="type">
                <el-radio-group v-model="info.type">
                    <el-radio :label="1">监测参数</el-radio>
                    <el-radio :label="2">设备参数</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>

        <span slot="footer">
            <el-button @click="infoShow = false">取消</el-button>
            <el-button type="primary" @click="subInfo">提交</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
export default {
    data() {
        return {
            filter: {
                keyword: ''
            },
            tableData: [],
            page: 1,
            maxpage: 1,
            perpage: 1,
            total: 0,
            infoShow: false,
            saveType: 1, // 1新增 2编辑
            info: {
                name: '',
                unit: '',
                icon1: '',
                icon2: '',
                explain: '',
                type: 1,
            },
            rules: {
                title: [{
                    required: true,
                    message: '请填写名称',
                    trigger: 'blur'
                }],
                code: [{
                    required: true,
                    message: '请填写编码',
                    trigger: 'blur'
                }],
            },
            regions: [],
            uploadUrl: 'adm/upload',
            headers: {
                Authorization:'Bearer '+localStorage.getItem('token')
            },
            customer_types: []
        }
    },
    mounted() {
        this.getTableData()
    },
    methods: {
        getTableData() {
            let data = this.filter
            data.page = this.page
            this.$util.get('datum_types', data).then(res => {
                this.loading = false
                this.tableData = res.data
                this.maxpage = res.last_page
                this.perpage = res.per_page
                this.total = res.total
            })
        },
        onSearch() {
            this.page = 1
            this.getTableData()
        },
        pageChange(page) {
            this.page = page
            this.getTableData()
        },
        onCreate() {
            this.saveType = 1
            this.infoShow = true
        },
        onEdit(row) {
            this.infoText = '修改信息'
            this.saveType = 2
            this.info = {
                id: row.id,
                name: row.name,
                icon1: row.icon1,
                icon2: row.icon2,
                unit: row.unit,
                explain: row.explain,
                type: row.type
            }
            this.infoShow = true
        },
        onDel(row) {
            this.$util.del('datum_types/' + row.id).then(res => {
                if(res.code > 0) {
                    this.$message({
                        type: 'error',
                        message: res.msg
                    });
                    return false
                }
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                this.getTableData()
            })
        },
        onQuick(id, field, val) {
            this.$util.put('datum_types/quick/' + id, {
                field: field,
                value: val
            }).then(() => {
                this.getTableData()
            })
        },
        subInfo() {
            this.$refs['info'].validate((valid) => {
                if (valid) {
                    if (this.saveType == 1) {
                        this.$util.post('datum_types', this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    } else {
                        this.$util.put('datum_types/' + this.info.id, this.info).then(() => {
                            this.getTableData()
                            this.infoShow = false
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        resetInfo() {
            this.info = {
                name: '',
                icon1: '',
                icon2: '',
                unit: '',
                explain: '',
                type: 1,
            }
        },
        changeStatus(row) {
            this.$util.put('datum_types/status', {
                id: row.id
            }).then(() => {
                this.getTableData()
            })
        },
        getCustomerTypes() {
            this.$util.get('ajax/customer_types').then(res => {
                this.customer_types = res
            })
        },
        beforeUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error('上传图片只能是 JPG,PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
        uploadIcon1Success(res) {
            this.info.icon1 = res.data;
        },
        uploadIcon2Success(res) {
            this.info.icon2 = res.data;
        },
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}
</style>
