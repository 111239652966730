import axios from 'axios';
import router from '../router/index.js';
import { Message } from 'element-ui';

axios.defaults.baseURL = '/adm';
axios.defaults.withCredentials = true
// 超时设置
axios.defaults.timeout = 120000;
// 请求拦截器
axios.interceptors.request.use(config => {
   // Do something before request is sent
   config.headers.common['content-type'] = 'application/json';
   if (localStorage.getItem('token')) {
      config.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
   }
   return config;
}, error => {
   // Do something with request error
   return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(
   response => {
      // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据     
      // 否则的话抛出错误
      if (response.status === 200) {
         return Promise.resolve(response);
      } else {
         return Promise.reject(response);
      }
   },
   // 服务器状态码不是2开头的的情况
   // 这里可以跟你们的后台开发人员协商好统一的错误状态码    
   // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
   // 下面列举几个常见的操作，其他需求可自行扩展
   error => {
      if (error.response && error.response.status) {
         switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。                
            case 401:
               localStorage.removeItem('token')
               router.replace({
                  path: '/login',
                  query: {
                     redirect: router.currentRoute.fullPath
                  }
               });
               break;

            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面                
            case 403:
               this.$message.error('登录过期，请重新登录')
               // 清除token
               localStorage.removeItem('token');
               // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
               setTimeout(() => {
                  router.replace({
                     path: '/login',
                     query: {
                        redirect: router.currentRoute.fullPath
                     }
                  });
               }, 1000);
               break;

            // 404请求不存在
            case 404:
               this.$message.error('网络请求不存在')
               break;
            // 其他错误，直接抛出错误提示
            default:
               this.$message.error(error.response.data.message)
         }
         return Promise.reject(error.response);
      }
   }
);


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
   return new Promise((resolve, reject) => {
      axios.get(url, {
         params: params
      })
         .then(response => {
            if (response.data && response.data.code > 0) {
               Message(response.data.msg)
               if (response.data.code == 401) {
                  localStorage.removeItem('token');
                  setTimeout(() => {
                     router.replace({
                        path: '/login',
                        query: {
                           redirect: router.currentRoute.fullPath
                        }
                     });
                  }, 1000);
               }
               reject(response)
            } else {
               resolve(response.data.data);
            }
         })
         .catch(err => {
            reject(err)
         })
   })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
   return new Promise((resolve, reject) => {
      axios.post(url, data)
         .then(response => {
            resolve(response.data);
         }, err => {
            reject(err)
         })
   })
}

/**
* 封装patch请求
* @param url
* @param data
* @returns {Promise}
*/

export function patch(url, data = {}) {
   return new Promise((resolve, reject) => {
      axios.patch(url, data)
         .then(response => {
            resolve(response.data);
         }, err => {
            reject(err)
         })
   })
}

/**
* 封装put请求
* @param url
* @param data
* @returns {Promise}
*/

export function put(url, data = {}) {
   return new Promise((resolve, reject) => {
      axios.put(url, data)
         .then(response => {
            resolve(response.data);
         }, err => {
            reject(err)
         })
   })
}

/**
* 封装delete请求
* @param url
* @param data
* @returns {Promise}
*/

export function del(url, data = {}) {
   return new Promise((resolve, reject) => {
      axios.delete(url, {data})
         .then(response => {
            resolve(response.data);
         }, err => {
            reject(err)
         })
   })
}
export let host = 'http://test.huarendjk.com'
export default {
   get,
   post,
   patch,
   put,
   del,
   host
}