<template>
<div class="page">
    <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据</el-breadcrumb-item>
            <el-breadcrumb-item>设备数据</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-row class="filter">
        <el-col :span="24">
            <el-form class="demo-form-inline" size="mini" label-width="100px">
                <el-form-item label="客户">
                    <el-select v-model="filter.customer" placeholder="请选择客户" clearable filterable @change="clearFilter">
                        <el-option v-for="item in customers" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备类型">
                    <el-checkbox-group v-model="filter.deviceTypes" @change="getDevices">
                        <el-checkbox v-for="item in device_types" :key="item.id" :label="item.id">{{item.name}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="设备">
                    <span v-if="filter.deviceTypes.length == 0" :style="{color: '#8888'}">请选择设备类型</span>
                    <el-checkbox-group v-model="filter.devices" multiple clearable filterable @change="getSensers" :style="{width: '100%'}">
                        <el-checkbox v-for="item in devices" :key="item.id" :label="item.id">{{item.name}}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="传感器">
                    <span v-if="filter.devices.length == 0" :style="{color: '#8888'}">请选择设备</span>
                    <el-checkbox-group v-model="filter.sensors" placeholder="请选择传感器" multiple clearable filterable @change="getData" :style="{width: '100%'}">
                        <el-checkbox v-for="item in sensors" :key="item.id" :label="item.id">{{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="参数">
                    <span v-if="filter.sensors.length == 0" :style="{color: '#8888'}">请选择传感器</span>
                    <el-radio-group v-model="filter.dataType" @change="setCurrentDatumType">
                        <el-radio v-for="item in data_types" :key="item.id" :label="item.name">
                            {{item.name}}
                        </el-radio>
                    </el-radio-group>
                    
                    <!-- <el-select v-model="filter.dataType" placeholder="请选择参数" clearable filterable>
                        <el-option v-for="item in data_types" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select> -->
                </el-form-item>
                <el-form-item>
                    <el-date-picker v-model="chartRange" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="请选择开始时间" end-placeholder="请选择结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch" size="mini">查询</el-button>
                    <el-button type="success" @click="onExport" size="mini">导出</el-button>
                </el-form-item>
            </el-form>
        </el-col>
    </el-row>

    <div class="row-table">
        <div ref="chart" class="chart"></div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            filter: {
                customer: '',
                devices: [],
                deviceTypes: [],
                sensors: [],
                dataType: ''
            },
            chart: '',
            chartRange: [],
            timer: '',
            chartData: [],
            customers: [],
            devices: [],
            device_types: [],
            sensors: [],
            data_types: [],
            currentDatumType: null,
        }
    },
    mounted() {
        this.getCustomers()
        this.getDeviceTypes()
        this.chartRange = [this.$moment().format('yyyy-MM-DD 00:00:00'), this.$moment().format('yyyy-MM-DD HH:mm:ss')]

    },
    methods: {
        onSearch() {
            if(this.filter.sensors.length < 1) {
                this.$message.error('请选择传感器')
                return false
            }
            if(this.filter.dataType == '') {
                this.$message.error('请选择参数类型')
                return false
            }
            var a = this.$moment(this.chartRange[0]);
            var b = this.$moment(this.chartRange[1]);
            var diffs = Math.abs(a.diff(b, 'months', true))
            if(diffs > 3) {
                this.$message.error('查询时间不能超过3个月')
                return false
            }
            if(this.chart){
                this.chart.dispose()
            }
            this.drawLine()
        },
        onExport(){
            if(this.filter.sensors.length < 1) {
                this.$message.error('请选择传感器')
                return false
            }
            if(this.filter.dataType == '') {
                this.$message.error('请选择参数类型')
                return false
            }
            window.open('https://test.wechatapp.inlems.com/adm/datum_logs_export?sensors='+this.filter.sensors+'&range='+this.chartRange)
        },
        getCustomers() {
            this.$util.get('ajax/customers').then(res=>{
                this.customers = res
            })
        },
        clearFilter() {
            this.filter.deviceTypes = []
            this.filter.sensors = []
            this.filter.devices = []
            this.filter.dataType = ''
            this.sensors = []
            this.devices = []
            this.data_types = []
        },
        getDeviceTypes() {
            this.$util.get('ajax/device_types').then(res=>{
                this.device_types = res
            })
        },
        getDevices() {
            this.filter.devices = []
            this.filter.sensors = []
            this.filter.dataType = ''
            this.sensors = []
            this.data_types = []
            this.devices = []
            if(!this.filter.customer) {
                this.$message.error('请先选择客户')
                this.filter.deviceTypes = []
                return false
            }
            this.$util.get('ajax/devices', {
                customer_id: this.filter.customer,
                device_types: this.filter.deviceTypes
            }).then(res=>{
                this.devices = res
            })
        },
        getSensers(val) {
            this.filter.sensors = []
            this.filter.dataType = ''
            this.sensors = []
            this.data_types = []
            this.$util.get('ajax/sensors', {
                device_ids: val
            }).then(res=>{
                this.sensors = res
            })
        },
        getData() {
            this.filter.dataType = ''
            this.data_types = []
            this.$util.get('ajax/datum_types', {
                sensor_ids: this.filter.sensors
            }).then(res=>{
                this.data_types = res
            })
        },
        setCurrentDatumType(val) {
            this.data_types.forEach(datumType => {
                if(datumType.name == val) {
                    this.currentDatumType = datumType
                }
            })
        },
        drawLine() {
            var _this = this
            this.$nextTick(() => {
                this.$util.get('ajax/datum_histories', {
                    sensor_ids: this.filter.sensors,
                    datum_type: this.filter.dataType,
                    range: this.chartRange
                }).then(res => {
                    _this.xdata = res.xdata
                    _this.series = res.series
                    let legends = res.legends
                    let visualMap = {}
                    let level = res.level
                    let interval = 10
                    let option = {
                        title: {
                            text: '设备历史数据查询',
                            left: 20
                        },
                        legend: {
                            data: legends,
                            left: 'center'
                        },
                        color: res.color,
                        tooltip: {
                            trigger: 'axis',
                            formatter: function (params) {
                                var html = params[0].axisValue+'\n'
                                params.forEach(items=>{
                                    html += items.marker + (items.value == '' ? '-' : items.value) + _this.currentDatumType.unit+'\n'
                                })
                                return html
                            }
                        },
                        grid: {
                            left: 30,
                            right: 25,
                            bottom: 45,
                            top: 100,
                            containLabel: true
                        },
                        xAxis: [{
                            type: 'category',
                            data: res.xdata,
                            boundaryGap: false,
                            axisLabel: {
                                fontSize: 8,
                                splitNumber: interval,
                                showMinLabel: true,
                                showMaxLabel: true,
                                formatter: function (value) {
                                    if(level == 1) {
                                        return value.substring(5, 10);
                                    }else if(level == 2) {
                                        return value.substring(5, 13);
                                    }else if(level == 3) {
                                        return value.substring(11, 16);
                                    }else{
                                        return value.substring(14, 19);
                                    }
                                }
                            }
                        }],
                        yAxis: {
                            type: 'value',
                            scale: true,
                            name: _this.currentDatumType.name + ' ' + _this.currentDatumType.unit,
                            min: 0,
                        },
                        dataZoom: [{
                                type: 'slider',
                                xAxisIndex: 0,
                                filterMode: 'none'
                            },
                            {
                                type: 'slider',
                                yAxisIndex: 0,
                                filterMode: 'none'
                            },
                            {
                                type: 'inside',
                                xAxisIndex: 0,
                                filterMode: 'none'
                            },
                            {
                                type: 'inside',
                                yAxisIndex: 0,
                                filterMode: 'none'
                            }
                        ],
                        series: res.series
                    }
                    _this.chart = _this.$echarts.init(_this.$refs['chart'])
                    // 绘制图表
                    _this.chart.setOption(option);
                    _this.chart.on('legendselectchanged', function (params) {
                        let count = 0;
                        let i = 0;
                        let selectedIndex = 0;
                        for (const k in params.selected) {
                            const value = params.selected[k];
                            if (value) {
                                selectedIndex = i
                                count++
                            }
                            i++
                        }
                        if (count == 1) {
                            console.log(_this.series[selectedIndex].min)
                            visualMap = {
                                top: 50,
                                right: 0,
                                pieces: [{
                                    gt: _this.series[selectedIndex].min,
                                    lte: _this.series[selectedIndex].max,
                                    color: '#67C23A'
                                }
                                ],
                                outOfRange: {
                                    color: '#f90000'
                                }
                            }
                            _this.chart.setOption({
                                visualMap: visualMap
                            })
                        }else{
                            _this.chart.dispose()
                            _this.drawLine()
                        }
                    })
                })
            })
        },
        // drowline() {
        //     this.$nextTick(() => {
        //         var _this = this
        //         _this.chartData = _this.randomData()
        //         _this.chart = _this.$echarts.init(_this.$refs['chart'])
        //         var option = {
        //             title: {
        //                 text: '数据查询展示图',
        //                 left: 'center'
        //             },
        //             tooltip: {
        //                 trigger: 'axis'
        //             },
        //             xAxis: {
        //                 type: 'time',
        //                 splitLine: {
        //                     show: false
        //                 }
        //             },
        //             yAxis: {
        //                 type: 'value',
        //                 name: 'TEMP',
        //                 boundaryGap: [0, '100%'],
        //                 nameGap: 30
        //             },
        //             animation: false,
        //             // dataZoom: [
        //             //     {
        //             //         type: 'inside',
        //             //         yAxisIndex: 0,
        //             //         filterMode: 'none'
        //             //     },
        //             //     {
        //             //         type: 'inside',
        //             //         xAxisIndex: 0,
        //             //         filterMode: 'none'
        //             //     }
        //             // ],
        //             series: [{
        //                 type: 'line',
        //                 showSymbol: false,
        //                 data: _this.chartData
        //             }]
        //         }
        //         _this.chart.setOption(option);
        //         _this.setTimer()
        //     })
        // },
        // setTimer() {
        //     this.timer = setInterval(() => {
        //         let rand = (Math.random() * 10 + 20).toFixed(2)
        //         let time = this.$moment().format('yyyy-MM-DD HH:mm:ss')
        //         let data = [time, rand]
        //         let chartData = this.chartData
        //         chartData.shift()
        //         chartData.push(data)
        //         this.chart.setOption({
        //             series: {
        //                 data: chartData
        //             }
        //         })
        //     }, 1000)
        // },
        // randomData() {
        //     let dataList = [];
        //     let len = 30
        //     for (let i = 0; i < len; i++) {
        //         let rand = (Math.random() * 10 + 20).toFixed(2)
        //         let time = this.$moment().subtract(len - i, 'days').format('yyyy-MM-DD HH:mm:ss')
        //         let data = [time, rand]
        //         dataList.push(data)
        //     }
        //     return dataList;
        // }
    }
}
</script>

<style scoped>
.btn-pop {
    margin-left: 10px;
}

.img {
    width: 60px;
}

.breadcrumb {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
}

.filter {
    background-color: #fff;
    padding: 20px 20px 0;
    border-radius: 10px;
    margin: 20px 0;
}

.row-table {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
}

.chart {
    height: 500px;
}
</style>
